import { useEffect, useState } from "react";
import axiosInstance from "../axiosInstance"; // Ensure this is the correct path to your axiosInstance
import Loader from "./Loader";

export default function Dashboard() {
  const [isLoading, setIsLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState({
    newOrders: 0,
    bounceRate: 0,
    userRegistrations: 0,
    uniqueVisitors: 0,
  });

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        setIsLoading(true);
        const response = await axiosInstance.get("/admin/dashboard"); // Adjust endpoint as needed
        console.log(response.data);
        const {
          Total_Users,
          Total_Unpaid_Users,
          Today_Live_Users,
          Total_Active_Users,
          Total_Inactive_Users,
          Total_Paid_Users,
          Yesterday_Live_Users,
        } = response.data.result;
        setDashboardData({
          Total_Users,
          Today_Live_Users,
          Total_Active_Users,
          Total_Inactive_Users,
          Total_Paid_Users,
          Yesterday_Live_Users,
          Total_Unpaid_Users,
        });
      } catch (error) {
        console.error("Error fetching dashboard data", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <div className="maintopmargin" >
      <div className="content-wrapper">
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 col-6">
                <div className="small-box bg-info">
                  <div className="inner">
                    <h3>{dashboardData.Total_Users}</h3>
                    <p>Total Users</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-person-add"></i>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="small-box bg-success">
                  <div className="inner">
                    <h3>{dashboardData.Today_Live_Users}</h3>
                    <p>Live Users</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-person-add"></i>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="small-box bg-info">
                  <div className="inner">
                    <h3>{dashboardData.Yesterday_Live_Users}</h3>
                    <p>Yesterday Live Users</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-person-add"></i>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-6">
                <div className="small-box bg-warning">
                  <div className="inner">
                    <h3>{dashboardData.Total_Active_Users}</h3>
                    <p>Active Users</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-person-add"></i>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="small-box bg-danger">
                  <div className="inner">
                    <h3>{dashboardData.Total_Inactive_Users}</h3>
                    <p>Inactive Users</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-person-add"></i>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-6">
                <div className="small-box bg-warning">
                  <div className="inner">
                    <h3>{dashboardData.Total_Paid_Users}</h3>
                    <p>Paid Users</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-person-add"></i>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-6">
                <div className="small-box bg-danger">
                  <div className="inner">
                    <h3>{dashboardData.Total_Unpaid_Users}</h3>
                    <p>Unpaid Users</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-person-add"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div >
  );
}
