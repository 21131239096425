import { Link } from "react-router-dom";

export default function PasswordResetSuccess() {
  return (
    <div>
      <h1> OOPS! </h1>
      <p>We're sorry, the page you requested could not be found</p>

      <Link to="/admin">
        <p className="ftrText">Go Back to Homepage</p>
      </Link>
    </div>
  );
}
