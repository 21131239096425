import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import ReactPaginate from "react-paginate";
import Loader from "../Loader";

export default function Users() {
  const [contacts, setContacts] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedPartnerPreference, setSelectedPartnerPreference] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedWeight, setSelectedWeight] = useState("");
  const [selectedCommunity, setSelectedCommunity] = useState("");
  const [selectedReligion, setSelectedReligion] = useState("");
  const [selectedFamilyType, setSelectedFamilyType] = useState("");
  const [selectedMaritalStatus, setSelectedMaritalStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async (page) => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(`/admin/all-users`, {
        params: {
          page,
          status: selectedStatus,
          partnerPreference: selectedPartnerPreference,
          gender: selectedGender,
          weight: selectedWeight,
          community: selectedCommunity,
          religion: selectedReligion,
          familyType: selectedFamilyType,
          maritalStatus: selectedMaritalStatus,
        },
      });
      setContacts(response.data.result.docs);
      setPageCount(response.data.result.totalPages);
    } catch (error) {
      console.error("Error fetching data", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [
    currentPage,
    selectedStatus,
    selectedPartnerPreference,
    selectedGender,
    selectedWeight,
    selectedCommunity,
    selectedReligion,
    selectedFamilyType,
    selectedMaritalStatus,
  ]);

  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    setCurrentPage(selectedPage);
    fetchData(selectedPage);
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
    setCurrentPage(1);
  };

  const handlePartnerPreferenceChange = (e) => {
    setSelectedPartnerPreference(e.target.value);
    setCurrentPage(1);
  };

  const handleGenderChange = (e) => {
    setSelectedGender(e.target.value);
    setCurrentPage(1);
  };

  const handleWeightChange = (e) => {
    setSelectedWeight(e.target.value);
    setCurrentPage(1);
  };

  const handleCommunityChange = (e) => {
    setSelectedCommunity(e.target.value);
    setCurrentPage(1);
  };

  const handleReligionChange = (e) => {
    setSelectedReligion(e.target.value);
    setCurrentPage(1);
  };

  const handleFamilyTypeChange = (e) => {
    setSelectedFamilyType(e.target.value);
    setCurrentPage(1);
  };

  const handleMaritalStatusChange = (e) => {
    setSelectedMaritalStatus(e.target.value);
    setCurrentPage(1);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const downloadUsersAsCSV = async () => {
    try {
      setIsLoading(true);

      // Prepare CSV content
      const csvData = contacts.map((user) => ({
        "Photo": `=HYPERLINK("${user.Photos[0]?.["25px"] || ""}", "View Photo")`,
        Username: `${user.Username}`,
        Name: `${user.Personal.FirstName} ${user.Personal.LastName}`,
        Email: user.Email,
        Status: user.Status,
        "Partner Preference": user.PartnerPreference,
        Education: user.EducationWork.Education.EducationName,
        "Passing Year": user.EducationWork.Education.PassingYear,
        "Work Title": user.EducationWork.Work.WorkTitle,
        "Company Name": user.EducationWork.Work.CompanyName,
        "Exact Income": user.EducationWork.Work.ExactIncome,
        City: user.CurrentAddress.City,
        State: user.CurrentAddress.State,
        Family: user.Family.FamilyType,
        "Father Status": user.Family.FatherStatus,
        "Mother Status": user.Family.MotherStatus,
        Brothers: user.Family.TotalBrothers,
        Sisters: user.Family.TotalSisters,
        Expectation: user.Additional.Expectation,
        Interest: user.Additional.Interest.join(" | "),
        // Include photo URL with hyperlink format
      }));

      // Generate CSV file content
      const header = Object.keys(csvData[0]).join(",") + "\n";
      const rows = csvData.map((row) => {
        return Object.values(row).map(value =>
          typeof value === "string" && value.includes(",")
            ? `"${value.replace(/"/g, '""')}"`
            : value
        ).join(",");
      }).join("\n");
      const csvContent = header + rows;

      // Create blob and download link
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `users_list_page_${currentPage}.csv`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error('Error downloading users list', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loader />;
  return (
    <div className="maintopmargin" >
      <div className="content-wrapper">
        {/* <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Users</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Users</li>
                </ol>
              </div>
            </div>
          </div>
        </section> */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3">
                <select
                  className="form-control mt-2"
                  name="select_status"
                  onChange={handleStatusChange}
                  value={selectedStatus}
                >
                  <option value="">All Users</option>
                  <option value="ACTIVE">ACTIVE</option>
                  <option value="PENDING">PENDING</option>
                  <option value="HIDE">HIDE</option>
                  <option value="DISABLED">DISABLED</option>
                  <option value="DELETED">DELETED</option>
                </select>
              </div>
              <div className="col-lg-3">
                <select
                  className="form-control mt-2"
                  name="select_partner_preference"
                  onChange={handlePartnerPreferenceChange}
                  value={selectedPartnerPreference}
                >
                  <option value="">Partner Preference</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Transgender">Transgender</option>
                </select>
              </div>
              <div className="col-lg-3">
                <select
                  className="form-control mt-2"
                  name="select_gender"
                  onChange={handleGenderChange}
                  value={selectedGender}
                >
                  <option value="">Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Transgender">Transgender</option>
                </select>
              </div>
              <div className="col-lg-3">
                <input
                  className="form-control mt-2"
                  type="number"
                  name="select_weight"
                  placeholder="Weight"
                  onChange={handleWeightChange}
                  value={selectedWeight}
                />
              </div>
              <div className="col-lg-3">
                <select
                  className="form-control mt-2"
                  name="select_community"
                  onChange={handleCommunityChange}
                  value={selectedCommunity}
                >
                  <option value="">Community</option>
                  <option value="Sunni">Sunni</option>
                  <option value="Rajput">Rajput</option>
                  <option value="Brahmin">Brahmin</option>
                  <option value="Maratha">Maratha</option>
                  <option value="Yadav">Yadav</option>
                  <option value="Jat">Jat</option>
                  <option value="Kayastha">Kayastha</option>

                  <option value="Agarwal">Agarwal</option>
                  <option value="Baniya">Baniya</option>
                  <option value="Kshatriya">Kshatriya</option>
                  <option value="Arora">Arora</option>
                  <option value="Khatri">Khatri</option>
                  <option value="Jatav">Jatav</option>

                  <option value="Kashyap">Kashyap</option>
                  <option value="Thakur">Thakur</option>
                  <option value="Gupta">Gupta</option>

                  <option value="Scheduled Caste(SC)">Scheduled Caste(SC)</option>
                  <option value="Kurmi">Kurmi</option>
                  <option value="Lingayat">Lingayat</option>

                  <option value="Teli">Teli</option>
                  <option value="Shwetamber">Shwetamber</option>
                  <option value="Vishwakarma">Vishwakarma</option>
                  <option value="Kunbi">Kunbi</option>
                  <option value="Sunnii">Sunnii</option>
                  <option value="Vanniyar">Vanniyar</option>
                  <option value="Kushwaha">Kushwaha</option>
                  <option value="Gursikh">Gursikh</option>
                  <option value="Reddy">Reddy</option>
                  <option value="Digambar">Digambar</option>
                  <option value="Reddy">Reddy</option>
                  <option value="Adi Dravida">Adi Dravida</option>
                  <option value="Patel">Patel</option>
                  <option value="Catholic">Catholic</option>



                </select>
              </div>
              <div className="col-lg-3">
                <select
                  className="form-control mt-2"
                  name="select_religion"
                  onChange={handleReligionChange}
                  value={selectedReligion}
                >
                  <option value="">Religion</option>
                  <option value="Hindu">Hindu</option>
                  <option value="Sikh">Sikh</option>
                  <option value="Jain">Jain</option>
                  <option value="Buddhist">Buddhist</option>
                  <option value="Christian">Christian</option>
                  <option value="Islam">Islam</option>
                </select>
              </div>
              <div className="col-lg-3">
                <select
                  className="form-control mt-2"
                  name="select_family_type"
                  onChange={handleFamilyTypeChange}
                  value={selectedFamilyType}
                >
                  <option value="">Family Type</option>
                  <option value="Joint">Joint</option>
                  <option value="Nuclear">Nuclear</option>
                </select>
              </div>
              <div className="col-lg-3">
                <select
                  className="form-control mt-2"
                  name="select_marital_status"
                  onChange={handleMaritalStatusChange}
                  value={selectedMaritalStatus}
                >
                  <option value="">Marital Status</option>
                  <option value="Unmarried">Unmarried</option>
                  <option value="Married">Married</option>
                  <option value="Divorced">Divorced</option>
                  <option value="Widowed">Widowed</option>
                </select>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <button
                      className="btn btn-success float-right ml-2"
                      onClick={downloadUsersAsCSV} // Changed function name
                    >
                      Download CSV
                    </button>

                  </div>



                  <div className="card-body table-responsive p-0">
                    <table className="table table-hover text-nowrap">
                      <thead>
                        <tr>
                          <th>Sl. No.</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Contact</th>
                          <th>Status</th>
                          <th>Last Session</th>
                          <th>Created At</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {contacts.map((contact, index) => (
                          <tr key={index}>
                            <td>{index + 1 + (currentPage - 1) * 10}</td>
                            <td>{contact.Personal.FirstName} {contact.Personal.LastName}</td>
                            <td>{contact.Email}</td>
                            <td>{contact.Personal.ContactNumber}</td>
                            <td>{contact.Status}</td>
                            <td>{formatDate(contact.LastSession)}</td>
                            <td>{formatDate(contact.CreatedAt)}</td>
                            <td>
                              <a href={`/admin/user_details?id=${contact._id}`}>View</a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="card-footer clearfix">
                    <ReactPaginate
                      previousLabel={"«"}
                      nextLabel={"»"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination pagination-sm m-0 float-right"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
