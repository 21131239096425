export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="main-footer">
      <div className="float-right d-none d-sm-block"></div>
      <strong>
        Copyright &copy; {currentYear}{" "}
        <a href="https:premmilan.com">Premmilan</a>.
      </strong>{" "}
      All rights reserved.
    </footer>
  );
}
