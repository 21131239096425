import { useEffect, useState } from "react";
import { Link, useHistory, useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import Header from "../includes/Header";
import Sidebar from "../includes/Sidebar";
import Footer from "../includes/Footer";
import Loader from "../Loader";

export default function KlickAdd() {
  const [formData, setFormData] = useState({
    Description: "",
    Video: null,
    ErrorMsg: null,
  });
  const navigate = useNavigate();
  const [videoSrc, setVideoSrc] = useState(null);
  const [loading, setLoading] = useState(false); // New loading state
  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("id");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axiosInstance.get("/klick/single/" + id);
        console.log(response.data.result);
        const { Description } = response.data.result;
        setFormData({
          Description: Description || "",
          Video: null,
          ErrorMsg: null,
        });
      } catch (error) {
        console.error("Error fetching data", error);
        setFormData((prevFormData) => ({
          ...prevFormData,
          ErrorMsg: "Error fetching package data",
        }));
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      Video: file,
    }));

    if (file) {
      const videoUrl = URL.createObjectURL(file);
      setVideoSrc(videoUrl);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Show loading indicator

    const data = new FormData();
    data.append("Description", formData.Description);
    if (formData.Video) {
      data.append("Video", formData.Video);
    }

    try {
      if (id) {
        await axiosInstance.put(`/klick/${id}`, {
          Description: formData.Description,
        });
        alert("Klick updated successfully");
      } else {
        await axiosInstance.post("/klick", data);
        alert("Klick added successfully");
      }
      // Clear form data after successful submission
      setFormData({
        Description: "",
        Video: null,
        ErrorMsg: null,
      });
      setVideoSrc(null);

      navigate("/admin/klicks");
    } catch (error) {
      console.error("Error submitting form", error);
      setFormData((prevFormData) => ({
        ...prevFormData,
        ErrorMsg: "Error submitting form",
      }));
    } finally {
      setLoading(false); // Hide loading indicator
    }
  };
  if (isLoading) return <Loader />;
  return (
    <div className="maintopmargin" >
      <div className="content-wrapper">
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">
                      {id ? "Update Klick" : "Add Klick"}
                    </h3>
                  </div>
                  <form role="form" onSubmit={handleSubmit}>
                    <div className="card-body">
                      {formData.ErrorMsg && (
                        <p style={{ color: "black" }}>{formData.ErrorMsg}</p>
                      )}

                      <div className="form-group">
                        <label htmlFor="description">Description</label>
                        <textarea
                          className="form-control"
                          rows="3"
                          name="Description"
                          placeholder="Enter Description..."
                          required
                          value={formData.Description}
                          onChange={handleInputChange}
                          aria-label="Description"
                        ></textarea>
                      </div>

                      {!id && (
                        <>
                          <div className="form-group">
                            <label htmlFor="video">Video</label>
                            <div className="input-group">
                              <div className="custom-file">
                                <input
                                  type="file"
                                  className="custom-file-input"
                                  accept="video/*"
                                  onChange={handleFileChange}
                                  aria-label="Video"
                                />
                                <label className="custom-file-label">
                                  Choose Video
                                </label>
                              </div>
                              <div className="input-group-append">
                                <span className="input-group-text">Upload</span>
                              </div>
                            </div>
                          </div>

                          {videoSrc && (
                            <div>
                              <p>New Video</p>
                              <video width="400" height="250" controls>
                                <source src={videoSrc} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        {id ? "Update Klick" : "Add Klick"}
                      </button>
                      {loading && <p>Uploading...</p>}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
