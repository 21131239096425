import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import _ from "lodash"
import Footer from "../includes/Footer";
import Loader from "../Loader";

export default function Packages() {
  const [packages, setPackages] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axiosInstance.get("/package");
        console.log(response.data.result);
        const pakages = _.orderBy(response.data.result, ['Type'], ['desc']);
        setPackages(pakages);
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleEdit = (id) => {
    navigate(`/admin/update_package?id=${id}`);
  };

  if (isLoading) return <Loader />;
  return (
    <div className="maintopmargin" >
      <div className="content-wrapper">
        {/* <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Packages</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Packages</li>
                </ol>
              </div>
            </div>
          </div>
        </section> */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Packages</h3>
                  </div>
                  <div className="card-body table-responsive p-0">
                    <table className="table table-hover text-nowrap">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Type</th>
                          <th>Name</th>
                          <th>Validity</th>
                          <th>Amount</th>
                          {/* <th>Requests Per Day</th> */}
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {packages.map((pkg, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{pkg.Type}</td>
                            <td>{pkg.Name}</td>
                            <td>{pkg.Validity}</td>
                            <td>{pkg.Amount}</td>
                            {/* <td>{pkg.RequestPerDay}</td> */}
                            <td>
                              <button
                                type="button"
                                className="btn btn-block btn-primary btn-sm"
                                onClick={() => handleEdit(pkg._id)}
                              >
                                Edit
                              </button>
                              {/* <button type="button" className="btn btn-block btn-danger btn-sm">Delete</button> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
