import { useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import Links from "./Links";

export default function Header() {
  const navigate = useNavigate();

  const changePassword = async () => {
    const confirmation = window.confirm(
      "Are you sure you want to change the password?"
    );
    if (confirmation) {
      try {
        await axiosInstance.post(`/admin/change-password`);
        alert("Password changed successfully");
        onLogout();
        navigate("/")
      } catch (error) {
        console.error("Error change password", error);
      }
    }
  }

  const onLogout = () => {
    localStorage.removeItem("auth");
    localStorage.removeItem("password");
  };

  return (
    <>
      <Links />
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6 alignItemCenter">
              <h1>Profile</h1>
            </div>
            <div className="col-sm-6">
              <div className="header-btns">
                <button className="btn btnLogout" onClick={changePassword}>Change Password</button>
                <a href="/" className="nav-link textRight">
                  <button className="btn btnLogout" onClick={onLogout}>Logout</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
}
