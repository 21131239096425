import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Login from "../components/Login";
import ErrorPage from "../components/Error";
import Root from "../components/Root";
import Dashboard from "../components/Dashboard";
import Subscription from "../components/subscription/Subscription";
import Packages from "../components/package/Packages";
import PackagesUpdate from "../components/package/PackagesUpdate";
import Coupons from "../components/coupons/Coupons";
import CouponsUpdate from "../components/coupons/CouponsUpdate";
import Contacts from "../components/Contacts";
import Transactions from "../components/Transactions.jsx";
import ReportUsers from "../components/ReportUsers.jsx";

import Aboutus from "../components/Aboutus.jsx";
import Blogs from "../components/blogs/Blog.jsx";
import BlogUpdate from "../components/blogs/BlogUpdate.jsx";
import Story from "../components/story/Story.jsx";
import StoryUpdate from "../components/story/StoryUpdate.jsx";
import Klicks from "../components/klicks/Klicks.jsx";
import KlickAdd from "../components/klicks/KlickAdd.jsx";
import Settings from "../components/Settings.jsx";
import Homepage from "../components/Homepage.jsx";

import Users from "../components/users/Users.jsx";
import UsersDetails from "../components/users/UsersDetails.jsx";
import ProtectedRoutes from "./ProtectedRoutes";
import AdminRoot from "../components/AdminRoot";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />}>
      <Route index element={<Login />} />
      <Route path="login" element={<Login />} />

      <Route element={<ProtectedRoutes />}>
        <Route path="admin" element={<AdminRoot />}>
          <Route index element={<Dashboard />} />
          <Route path="subscription" element={<Subscription />} />
          <Route path="packages" element={<Packages />} />
          <Route path="coupons" element={<Coupons />} />
          <Route path="contacts" element={<Contacts />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="update_coupon" element={<CouponsUpdate />} />
          <Route path="update_package" element={<PackagesUpdate />} />
          <Route path="update_blog" element={<BlogUpdate />} />
          <Route path="klicks" element={<Klicks />} />
          <Route path="add_klick" element={<KlickAdd />} />
          <Route path="aboutus" element={<Aboutus />} />
          <Route path="stories" element={<Story />} />
          <Route path="update_story" element={<StoryUpdate />} />
          <Route path="transactions" element={<Transactions />} />
          <Route path="report_users" element={<ReportUsers />} />
          <Route path="settings" element={<Settings />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="users" element={<Users />} />
          <Route path="user_details" element={<UsersDetails />} />
          <Route path="homepage" element={<Homepage />} />
        </Route>
      </Route>
      <Route path="*" element={<ErrorPage />} />
    </Route>
  )
);

export default router;
