import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../axiosInstance";

import Footer from "./includes/Footer";
import Loader from "./Loader";

export default function Homepage() {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    Title1: "",
    Title2: "",
    Service_Title: "",
    Service_Image: null,
    Service_Image_URL: null,  // for preview
    Service_Description: "",
    Banner: null,
    Banner_URL: null,  // for preview
    Security_Points: [{ text: "" }],
    ErrorMsg: null,
    SuccessMsg: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axiosInstance.get(`/admin/homepage`);
        console.log(response.data.result);

        const { Title1, Title2, Service_Title, Service_Image, Service_Description, Banner, Security_Points } = response.data.result;
        setFormData({
          Title1: Title1 || "",
          Title2: Title2 || "",
          Service_Title: Service_Title || "",
          Service_Image: Service_Image || null,
          Service_Image_URL: Service_Image || null,
          Service_Description: Service_Description || "",
          Banner: Banner || null,
          Banner_URL: Banner || null,
          Security_Points: Security_Points.length ? Security_Points : [{ text: "" }],
          ErrorMsg: null,
          SuccessMsg: null,
        });
      } catch (error) {
        console.error("Error fetching data", error);
        setFormData((prevFormData) => ({
          ...prevFormData,
          ErrorMsg: "Error fetching data",
          SuccessMsg: null,
        }));
      }
      finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: file,
      [`${name}_URL`]: URL.createObjectURL(file),
    }));
  };

  const handleSecurityPointChange = (index, value) => {
    const newSecurityPoints = [...formData.Security_Points];
    newSecurityPoints[index].text = value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      Security_Points: newSecurityPoints,
    }));
  };

  const addSecurityPoint = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      Security_Points: [...prevFormData.Security_Points, { text: "" }],
    }));
  };

  const deleteSecurityPoint = (index) => {
    const newSecurityPoints = [...formData.Security_Points];
    newSecurityPoints.splice(index, 1);
    setFormData((prevFormData) => ({
      ...prevFormData,
      Security_Points: newSecurityPoints,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Filter out blank security points
    const filteredSecurityPoints = formData.Security_Points.filter(point => point.text.trim() !== "");

    let serviceImageUrl = formData.Service_Image;
    let bannerUrl = formData.Banner;

    if (typeof formData.Service_Image !== "string") {
      const imageData = new FormData();
      imageData.append('Image', formData.Service_Image);

      const imageUploadResponse = await axiosInstance.post('/admin/upload-image', imageData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      serviceImageUrl = imageUploadResponse.data.result; // Adjust based on your API response structure
    }

    if (typeof formData.Banner !== "string") {
      const bannerData = new FormData();
      bannerData.append('Image', formData.Banner);

      const bannerUploadResponse = await axiosInstance.post('/admin/upload-image', bannerData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      bannerUrl = bannerUploadResponse.data.result; // Adjust based on your API response structure
    }

    try {
      setIsLoading(true);
      await axiosInstance.put("/admin/homepage", {
        Title1: formData.Title1,
        Title2: formData.Title2,
        Service_Title: formData.Service_Title,
        Service_Image: serviceImageUrl,
        Service_Description: formData.Service_Description,
        Banner: bannerUrl,
        Security_Points: filteredSecurityPoints, // Use filtered security points
      });

      setFormData((prevFormData) => ({
        ...prevFormData,
        SuccessMsg: "Home Page updated successfully!",
        ErrorMsg: null,
      }));
    } catch (error) {
      console.error("Error saving data", error);
      setFormData((prevFormData) => ({
        ...prevFormData,
        ErrorMsg: "Error saving data",
        SuccessMsg: null,
      }));
    }
    finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loader />;

  return (
    <div className="maintopmargin" >
      <div className="content-wrapper">
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Home Page</h3>
                  </div>
                  <form role="form" onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-6">
                          <label htmlFor="Title1">Title 1</label>
                          <input
                            type="text"
                            className="form-control"
                            name="Title1"
                            placeholder="Enter Title 1"
                            required
                            value={formData.Title1}
                            onChange={handleInputChange}
                            aria-label="Title1"
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="Title2">Title 2</label>
                          <input
                            type="text"
                            className="form-control"
                            name="Title2"
                            placeholder="Enter Title 2"
                            required
                            value={formData.Title2}
                            onChange={handleInputChange}
                            aria-label="Title2"
                          />
                        </div>
                      </div>
                      <br />
                      <div className="col-lg-6">
                        <label htmlFor="Banner">Banner</label>
                        <input
                          type="file"
                          className="form-control"
                          name="Banner"
                          onChange={handleFileChange}
                          aria-label="Banner"
                        />
                        {formData.Banner_URL && (
                          <div style={{ marginTop: "10px" }}>
                            <img src={formData.Banner_URL} alt="Banner" style={{ width: "100%", maxWidth: "200px" }} />
                          </div>
                        )}
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-lg-6">
                          <label htmlFor="Service_Title">Service Title</label>
                          <input
                            type="text"
                            className="form-control"
                            name="Service_Title"
                            placeholder="Enter Service Title"
                            required
                            value={formData.Service_Title}
                            onChange={handleInputChange}
                            aria-label="Service_Title"
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="Service_Image">Service Image</label>
                          <input
                            type="file"
                            className="form-control"
                            name="Service_Image"
                            onChange={handleFileChange}
                            aria-label="Service_Image"
                          />
                          {formData.Service_Image_URL && (
                            <div style={{ marginTop: "10px" }}>
                              <img src={formData.Service_Image_URL} alt="Service Image" style={{ width: "100%", maxWidth: "200px" }} />
                            </div>
                          )}
                        </div>
                      </div>
                      <br />
                      <div className="col-lg-12">
                        <label htmlFor="Service_Description">Service Description</label>
                        <textarea
                          className="textarea"
                          placeholder="Enter Service Description"
                          style={{
                            width: "100%",
                            height: "200px",
                            fontSize: "14px",
                            lineHeight: "18px",
                            border: "1px solid #dddddd",
                            padding: "10px",
                          }}
                          name="Service_Description"
                          value={formData.Service_Description}
                          onChange={handleInputChange}
                        ></textarea>
                      </div>
                      <br />
                      <div className="col-lg-12">
                        <label>Security Points</label>
                        {formData.Security_Points.map((point, index) => (
                          <div key={index} style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                            <input
                              type="text"
                              className="form-control"
                              name={`Security_Point_${index}`}
                              placeholder={`Enter Security Point ${index + 1}`}
                              value={point.text}
                              onChange={(e) => handleSecurityPointChange(index, e.target.value)}
                              aria-label={`Security_Point_${index}`}
                              style={{ marginRight: "10px" }}
                            />
                            <button type="button" className="btn btn-danger" onClick={() => deleteSecurityPoint(index)}>Delete</button>
                          </div>
                        ))}
                        <button type="button" className="btn btn-secondary" onClick={addSecurityPoint}>
                          Add More
                        </button>
                      </div>
                      <br />
                      {formData.ErrorMsg && <p style={{ color: "red" }}>{formData.ErrorMsg}</p>}
                      {formData.SuccessMsg && <p style={{ color: "green" }}>{formData.SuccessMsg}</p>}
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">Update</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
