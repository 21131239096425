import { Outlet } from "react-router-dom";
import Header from "./includes/Header";
import Sidebar from "./includes/Sidebar";

const AdminRoot = () => {
  return (
    <div>
      <div className="fixed-content">
        <Header />
        <Sidebar />
      </div>
      <Outlet />
    </div>
  );
};

export default AdminRoot;
