import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function Sidebar() {
  const location = useLocation();
  const [isMenuOpen1, setIsMenuOpen1] = useState(location.pathname.includes('coupons') ? true : location.pathname.includes('update_coupon') ? true : false);
  const [isMenuOpen2, setIsMenuOpen2] = useState(location.pathname.includes('stories') ? true : location.pathname.includes('update_story') ? true : false);
  const [isMenuOpen3, setIsMenuOpen3] = useState(location.pathname.includes('blogs') ? true : location.pathname.includes('update_blog') ? true : false);
  const [isMenuOpen4, setIsMenuOpen4] = useState(location.pathname.includes('klicks') ? true : location.pathname.includes('add_klick') ? true : false);

  const handleMenuToggle1 = () => {
    setIsMenuOpen1(!isMenuOpen1);
  };

  const handleMenuToggle2 = () => {
    setIsMenuOpen2(!isMenuOpen2);
  };

  const handleMenuToggle3 = () => {
    setIsMenuOpen3(!isMenuOpen3);
  };

  const handleMenuToggle4 = () => {
    setIsMenuOpen4(!isMenuOpen4);
  };

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <a href="https://premmilan.com" className="brand-link">
        <img
          src="https://premmilan.s3.ap-south-1.amazonaws.com/website/28401-logo.png"
          alt="Premmilan Logo"
          className="brand-image"
        />
        <span className="brand-text font-weight-light">.</span>
      </a>

      <div className="sidebar">
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            {/* <img src="../dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image" /> */}
          </div>
          <div className="info">
            <Link to="/admin/dashboard" className="d-block">Admin Panel</Link>
          </div>
        </div>

        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li className="nav-item">
              <a href="dashboard" className={`nav-link ${location.pathname.includes('dashboard') ? 'active' : ''}`}>
                <i className="nav-icon fas fa-file"></i>
                <p>Dashboard</p>
              </a>
            </li>
            <li className="nav-item">
              <a href="homepage" className={`nav-link ${location.pathname.includes('homepage') ? 'active' : ''}`}>
                <i className="nav-icon fas fa-file"></i>
                <p>Home Page</p>
              </a>
            </li>
            <li className="nav-item">
              <a href="users" className={`nav-link ${location.pathname.includes('users') ? 'active' : ''}`}>
                <i className="nav-icon fas fa-file"></i>
                <p>Users</p>
              </a>
            </li>
            <li className="nav-item">
              <a href="aboutus" className={`nav-link ${location.pathname.includes('aboutus') ? 'active' : ''}`}>
                <i className="nav-icon fas fa-file"></i>
                <p>About Us</p>
              </a>
            </li>
            <li className="nav-item">
              <a href="subscription" className={`nav-link ${location.pathname.includes('subscription') ? 'active' : ''}`}>
                <i className="nav-icon fas fa-file"></i>
                <p>Subscription</p>
              </a>
            </li>
            <li className="nav-item">
              <a href="packages" className={`nav-link ${location.pathname.includes('packages') ? 'active' : ''}`}>
                <i className="nav-icon fas fa-file"></i>
                <p>Packages</p>
              </a>
            </li>
            <li className={`nav-item has-treeview ${isMenuOpen1 ? 'menu-open' : ''}`}>
              <a href="javascript:void(0)" className={`nav-link  ${location.pathname.includes('coupons') ? 'active' : ''} ${location.pathname.includes('update_coupon') ? 'active' : ''}`} onClick={handleMenuToggle1}>
                <i className="nav-icon fas fa-tachometer-alt"></i>
                <p>
                  Coupons
                  <i className="right fas fa-angle-left"></i>
                </p>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <a href="update_coupon" className={`nav-link  ${location.pathname.includes('update_coupon') ? 'active' : ''}`}>
                    <i className="far fa-circle nav-icon"></i>
                    <p>Add Coupon</p>
                  </a>
                </li>
                <li className="nav-item">
                  <a href="coupons" className={`nav-link  ${location.pathname.includes('coupons') ? 'active' : ''}`}>
                    <i className="far fa-circle nav-icon"></i>
                    <p>List Coupon</p>
                  </a>
                </li>
              </ul>
            </li>
            <li className={`nav-item has-treeview ${isMenuOpen2 ? 'menu-open' : ''}`}>
              <a href="javascript:void(0)" className={`nav-link ${location.pathname.includes('stories') ? 'active' : location.pathname.includes('update_story') ? 'active' : ''}`} onClick={handleMenuToggle2}>
                <i className="nav-icon fas fa-tachometer-alt"></i>
                <p>
                  Happy Stories
                  <i className="right fas fa-angle-left"></i>
                </p>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <a href="update_story" className={`nav-link ${location.pathname.includes('update_story') ? 'active' : ''}`}>
                    <i className="far fa-circle nav-icon"></i>
                    <p>Add Story</p>
                  </a>
                </li>
                <li className="nav-item">
                  <a href="stories" className={`nav-link ${location.pathname.includes('stories') ? 'active' : ''}`}>
                    <i className="far fa-circle nav-icon"></i>
                    <p>List Story</p>
                  </a>
                </li>
              </ul>
            </li>
            <li className={`nav-item has-treeview ${isMenuOpen3 ? 'menu-open' : ''}`}>
              <a href="javascript:void(0)" className={`nav-link ${location.pathname.includes('blogs') ? 'active' : location.pathname.includes('update_blog') ? 'active' : ''}`} onClick={handleMenuToggle3}>
                <i className="nav-icon fas fa-tachometer-alt"></i>
                <p>
                  Blogs
                  <i className="right fas fa-angle-left"></i>
                </p>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <a href="update_blog" className={`nav-link  ${location.pathname.includes('update_blog') ? 'active' : ''}`}>
                    <i className="far fa-circle nav-icon"></i>
                    <p>Add Blog</p>
                  </a>
                </li>
                <li className="nav-item">
                  <a href="blogs" className={`nav-link  ${location.pathname.includes('blogs') ? 'active' : ''}`}>
                    <i className="far fa-circle nav-icon"></i>
                    <p>List Blog</p>
                  </a>
                </li>
              </ul>
            </li>
            <li className={`nav-item has-treeview ${isMenuOpen4 ? 'menu-open' : ''}`}>
              <a href="javascript:void(0)" className={`nav-link ${location.pathname.includes('klicks') ? 'active' : location.pathname.includes('add_klick') ? 'active' : ''}`} onClick={handleMenuToggle4}>
                <i className="nav-icon fas fa-tachometer-alt"></i>
                <p>
                  Klicks
                  <i className="right fas fa-angle-left"></i>
                </p>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <a href="add_klick" className={`nav-link  ${location.pathname.includes('add_klick') ? 'active' : ''}`}>
                    <i className="far fa-circle nav-icon"></i>
                    <p>Add Klick</p>
                  </a>
                </li>
                <li className="nav-item">
                  <a href="klicks" className={`nav-link  ${location.pathname.includes('klicks') ? 'active' : ''}`}>
                    <i className="far fa-circle nav-icon"></i>
                    <p>List Klick</p>
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <a href="transactions" className={`nav-link ${location.pathname.includes('transactions') ? 'active' : ''}`}>
                <i className="nav-icon fas fa-file"></i>
                <p>Transactions</p>
              </a>
            </li>
            <li className="nav-item">
              <a href="contacts" className={`nav-link ${location.pathname.includes('contacts') ? 'active' : ''}`}>
                <i className="nav-icon fas fa-file"></i>
                <p>Contact Us</p>
              </a>
            </li>
            <li className="nav-item">
              <a href="report_users" className={`nav-link ${location.pathname.includes('report_users') ? 'active' : ''}`}>
                <i className="nav-icon fas fa-file"></i>
                <p>Report Users</p>
              </a>
            </li>
            <li className="nav-item">
              <a href="settings" className={`nav-link ${location.pathname.includes('settings') ? 'active' : ''}`}>
                <i className="nav-icon fas fa-file"></i>
                <p>Settings</p>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
}
