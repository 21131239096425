import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import Header from "../includes/Header";
import Sidebar from "../includes/Sidebar";
import Footer from "../includes/Footer";
import ReactPaginate from "react-paginate";
import { Modal, Button } from "react-bootstrap";
import Loader from "../Loader";

export default function Blog() {
  const [blogs, setBlogs] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const navigate = useNavigate();
  const blogsPerPage = 5; // Set the number of blogs per page
  const [selectedImage, setSelectedImage] = useState(null); // State to store the clicked image details
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axiosInstance.get(
          `/blog?page=${currentPage + 1}&limit=${blogsPerPage}`
        );
        console.log(response.data.result);
        setBlogs(response.data.result.docs);
        setPageCount(response.data.result.totalPages); // Ensure this matches your API response structure
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [currentPage]);

  const handleEdit = (id) => {
    navigate(`/admin/update_blog?id=${id}`);
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this blog?"
    );
    if (confirmDelete) {
      try {
        setIsLoading(true);
        await axiosInstance.delete(`/blog/${id}`);
        setBlogs((prevBlogs) => prevBlogs.filter((blog) => blog._id !== id));
      } catch (error) {
        console.error("Error deleting blog", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  if (isLoading) return <Loader />;

  return (
    <div className="maintopmargin" >
      <div className="content-wrapper">
        {/* <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Blogs</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/update_blog">Add Blog</Link>
                  </li>
                  <li className="breadcrumb-item active">Blogs</li>
                </ol>
              </div>
            </div>
          </div>
        </section> */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Blogs</h3>
                  </div>
                  <div className="card-body table-responsive p-0">
                    <table className="table table-hover text-nowrap">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Title</th>
                          <th>Image</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {blogs.map((blog, index) => (
                          <tr key={blog._id}>
                            <td>{index + 1 + currentPage * blogsPerPage}</td>
                            <td>{blog.Title}</td>
                            <td>
                              {" "}
                              <img
                                src={blog.Image}
                                alt={blog.Title}
                                style={{ width: "100px", cursor: "pointer" }}
                                onClick={() => handleImageClick(blog.Image)}
                              />
                            </td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-block btn-primary btn-sm"
                                onClick={() => handleEdit(blog._id)}
                              >
                                Edit
                              </button>
                              <button
                                type="button"
                                className="btn btn-block btn-danger btn-sm"
                                onClick={() => handleDelete(blog._id)}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="card-footer clearfix">
                    <ReactPaginate
                      previousLabel={"«"}
                      nextLabel={"»"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={
                        "pagination pagination-sm m-0 float-right"
                      }
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />

      <Modal size="lg" show={!!selectedImage} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="popup-image-container">
            <img
              src={selectedImage}
              alt="Enlarged Blog Image"
              height={450}
              width={765}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
