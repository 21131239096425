import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInstance";
import Header from "./includes/Header";
import Sidebar from "./includes/Sidebar";
import Footer from "./includes/Footer";
import Loader from "./Loader";

export default function Transactions() {
  const [packages, setPackages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axiosInstance.get(
          "/payment/transactions?page=1"
        );
        console.log(response.data.result);
        setPackages(response.data.result.docs); // Adjust this based on your API response structure
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) return <Loader />;

  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }

  return (
    <div className="maintopmargin" >
      <div className="content-wrapper">
        {/* <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Transactions</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Transactions </li>
                </ol>
              </div>
            </div>
          </div>
        </section> */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Transactions</h3>
                  </div>
                  <div className="card-body table-responsive p-0">
                    <table className="table table-hover text-nowrap">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>TransactionId</th>
                          <th>Txt Status</th>
                          <th>Order Id</th>
                          <th>Package</th>
                          <th>User Email</th>
                          <th>Original Amount</th>
                          <th>Discount Amount</th>
                          <th>Amount</th>
                          <th>Coupon Code</th>
                          <th>Referral Name</th>
                          <th>Referral Number</th>
                          <th>Purchase At</th>
                        </tr>
                      </thead>
                      <tbody>
                        {packages.map((pkg, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{pkg.TransactionId}</td>
                            <td>
                              <span class="badge bg-success">{pkg.Status}</span>
                            </td>
                            <td>{pkg.OrderId}</td>
                            <td>{pkg.PackageName}</td>
                            <td><a href={`/admin/user_details?id=${pkg.UserId}`}>{pkg.User_Email}</a></td>
                            <td>{pkg.OriginalAmount}</td>
                            <td>{pkg.DiscountAmount}</td>
                            <td>{pkg.Amount}</td>
                            <td>{pkg.CouponCode.toUpperCase()}</td>
                            <td>{pkg.ReferralName}</td>
                            <td>{pkg.ReferralNumber}</td>
                            <td>{formatDate(pkg.CreatedAt)}</td>

                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
