// src/axiosInstance.js
import axios from "axios";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL, // Replace with your API base URL
  // withCredentials: true,
});

axiosInstance.interceptors.request.use((config) => {
  const auth = localStorage.getItem("auth");
  const password = localStorage.getItem("password");
  config.headers = config.headers || {};
  config.headers["user"] = auth;
  config.headers["password"] = password;
  return config;
});

export default axiosInstance;
