import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../axiosInstance";
import Header from "./includes/Header";
import Sidebar from "./includes/Sidebar";
import ReactPaginate from "react-paginate";
import Loader from "./Loader";

export default function ReportUsers() {
  const [packages, setPackages] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const fetchData = async (page) => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(`/user/report?page=${page + 1}`);
      console.log(response.data.result);
      setPackages(response.data.result.docs);
      setPageCount(response.data.result.totalPages); // Adjust this based on your API response structure
    } catch (error) {
      console.error("Error fetching data", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  if (isLoading) return <Loader />;
  return (
    <div className="maintopmargin" >
      <div className="content-wrapper">
        {/* <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Report Users</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Report Users</li>
                </ol>
              </div>
            </div>
          </div>
        </section> */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Report Users</h3>
                  </div>
                  <div className="card-body table-responsive p-0">
                    <table className="table table-hover text-nowrap">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Title</th>
                          <th>Description</th>
                          <th>Report Of User</th>
                          <th>Report By User</th>
                        </tr>
                      </thead>
                      <tbody>
                        {packages.map((pkg, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{pkg.Title}</td>
                            <td>{pkg.Description}</td>
                            <td>
                              {pkg.ReportOfFirstName || pkg.ReportOfEmail &&
                                <a href={`/admin/user_details?id=${pkg.ReportOfUserId}`}>
                                  {pkg.ReportOfFirstName} {pkg.ReportOfLastName}{" "}
                                  <br /> <b>({pkg.ReportOfEmail})</b>
                                </a>
                              }
                            </td>


                            <td>
                              <a href={`/admin/user_details?id=${pkg.ReportByUserId}`}>
                                {pkg.ReportByFirstName} {pkg.ReportByLastName}{" "}
                              </a>
                              <br />

                              <b>(<a href={`/admin/user_details?id=${pkg.ReportByUserId}`}>{pkg.ReportByEmail}</a>)</b>

                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="card-footer clearfix">
                    <ReactPaginate
                      previousLabel={"«"}
                      nextLabel={"»"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={
                        "pagination pagination-sm m-0 float-right"
                      }
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
