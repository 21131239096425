import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import axiosInstance from "../../axiosInstance";
import Footer from "../includes/Footer";
import Loader from "../Loader";

export default function Klicks() {
  const [blogs, setBlogs] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const navigate = useNavigate();
  const blogsPerPage = 10; // Number of blogs per page
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async (page) => {
      try {
        setIsLoading(true);
        const response = await axiosInstance.get(
          `/klick/all?page=${page + 1}&limit=${blogsPerPage}`
        );
        console.log(response.data.result);
        setBlogs(response.data.result.docs); // Ensure this matches your API response structure
        setPageCount(response.data.result.totalPages); // Ensure this matches your API response structure
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData(currentPage);
  }, [currentPage]);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this blog?"
    );
    if (confirmDelete) {
      try {
        setIsLoading(true);
        await axiosInstance.delete(`/klick/${id}`);
        setBlogs((prevBlogs) => prevBlogs.filter((blog) => blog._id !== id));
      } catch (error) {
        console.error("Error deleting blog", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const truncateDescription = (description, maxLength = 1000000) => {
    const words = description.split(" ");
    let result = "";
    for (let i = 0; i < words.length; i += 10) {
      result += words.slice(i, i + 10).join(" ") + "<br />";
    }
    return result.length <= maxLength
      ? result
      : result.substring(0, maxLength) + "...";
  };

  const handleEdit = (id) => {
    navigate(`/admin/add_klick?id=${id}`);
  };

  if (isLoading) return <Loader />;

  return (
    <div className="maintopmargin" >
      <div className="content-wrapper">
        {/* <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Klick</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/add_klick">Add Klick</Link>
                  </li>
                  <li className="breadcrumb-item active">Klick</li>
                </ol>
              </div>
            </div>
          </div>
        </section> */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Klick</h3>
                  </div>
                  <div className="card-body table-responsive p-0">
                    <table className="table table-hover text-nowrap">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Video</th>
                          <th>Slug</th>
                          <th>Description</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {blogs.map((blog, index) => (
                          <tr key={blog._id}>
                            <td>{index + 1 + currentPage * blogsPerPage}</td>
                            <td>
                              <video width="300" height={200} controls>
                                <source src={blog.Url} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            </td>
                            <td>{blog.Slug}</td>
                            <td
                              title={blog.Description}
                              dangerouslySetInnerHTML={{
                                __html: truncateDescription(blog.Description),
                              }}
                            ></td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-block btn-primary btn-sm"
                                onClick={() => handleEdit(blog._id)}
                              >
                                Edit
                              </button>
                              <button
                                type="button"
                                className="btn btn-block btn-danger btn-sm"
                                onClick={() => handleDelete(blog._id)}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="card-footer clearfix">
                    <ReactPaginate
                      previousLabel={"«"}
                      nextLabel={"»"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={
                        "pagination pagination-sm m-0 float-right"
                      }
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
