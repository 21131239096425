import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import Header from "../includes/Header";
import Sidebar from "../includes/Sidebar";
import Footer from "../includes/Footer";
import Loader from "../Loader";

export default function Subscription() {
  const [formData, setFormData] = useState({
    Title: "",
    Description: "",
    Video: null,
    ErrorMsg: null,
    SuccessMsg: null,
  });

  const [videoSrc, setVideoSrc] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axiosInstance.get("/admin/subscription");
        const { Title, Description, Video } = response.data.result;
        setFormData({
          Title: Title || "",
          Description: Description || "",
          Video: Video || "",
          ErrorMsg: null,
          SuccessMsg: null,
        });
      } catch (error) {
        console.error("Error fetching data", error);
        setFormData((prevFormData) => ({
          ...prevFormData,
          ErrorMsg: "Error fetching data",
        }));
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      Video: file,
    }));

    if (file) {
      const videoUrl = URL.createObjectURL(file);
      setVideoSrc(videoUrl);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let video = formData.Video;

    try {
      setIsLoading(true);
      if (typeof video !== "string") {
        const videoData = new FormData();
        videoData.append("Video", formData.Video);

        const videoUploadResponse = await axiosInstance.post(
          "/admin/upload-video",
          videoData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        video = videoUploadResponse.data.result; // Adjust based on your API response structure
      }

      await axiosInstance.put("/admin/subscription", {
        Title: formData.Title,
        Description: formData.Description,
        Video: video,
      });

      setFormData((prevFormData) => ({
        ...prevFormData,
        SuccessMsg: "Subscription data updated successfully",
        ErrorMsg: null,
      }));
    } catch (error) {
      console.error("Error updating subscription", error);
      setFormData((prevFormData) => ({
        ...prevFormData,
        ErrorMsg: "Error updating subscription",
        SuccessMsg: null,
      }));
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loader />;
  return (
    <div className="maintopmargin" >
      <div className="content-wrapper">
        {/* <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Subscription</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Subscription</li>
                </ol>
              </div>
            </div>
          </div>
        </section> */}

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Subscription</h3>
                  </div>
                  <form role="form" onSubmit={handleSubmit}>
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="Title">Title</label>
                        <input
                          type="text"
                          className="form-control"
                          name="Title"
                          placeholder="Enter Title"
                          required
                          value={formData.Title}
                          onChange={handleInputChange}
                          aria-label="Title"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="Description">Description</label>
                        <textarea
                          className="form-control"
                          rows="3"
                          name="Description"
                          placeholder="Enter Description..."
                          required
                          value={formData.Description}
                          onChange={handleInputChange}
                          aria-label="Description"
                        ></textarea>
                      </div>
                      <div className="form-group">
                        <label htmlFor="Video">Video</label>
                        <div className="input-group">
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input"
                              accept="video/*"
                              onChange={handleFileChange}
                              aria-label="Video"
                            />
                            <label className="custom-file-label">
                              Choose Video
                            </label>
                          </div>
                          <div className="input-group-append">
                            <span className="input-group-text">Upload</span>
                          </div>
                        </div>
                      </div>

                      {videoSrc && (
                        <div className="form-group">
                          <video width="400" height="250" controls>
                            <source src={videoSrc} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      )}

                      {formData.Video && typeof formData.Video === "string" && (
                        <div className="form-group">
                          <video width="400" height="250" controls>
                            <source src={formData.Video} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      )}

                      {formData.ErrorMsg && (
                        <p style={{ color: "red" }}>{formData.ErrorMsg}</p>
                      )}
                      {formData.SuccessMsg && (
                        <p style={{ color: "green" }}>{formData.SuccessMsg}</p>
                      )}
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
