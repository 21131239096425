import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../axiosInstance";
import Footer from "./includes/Footer";
import Loader from "./Loader";

export default function Settings() {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    Email: "",
    Phone: "",
    Working_Time: "",
    Logo: null,
    Music: null,
    Facebook: "",
    Twitter: "",
    Instagram: "",
    Youtube: "",
    ErrorMsg: null,
  });

  const [logoSrc, setLogoSrc] = useState(null);
  const [musicSrc, setMusicSrc] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axiosInstance.get("/admin/settings");
        console.log(response.data.result);
        const {
          Email,
          Phone,
          Working_Time,
          Logo,
          Music,
          Facebook,
          Twitter,
          Instagram,
          Youtube,
        } = response.data.result;
        setFormData({
          Email: Email || "",
          Phone: Phone || "",
          Working_Time: Working_Time || "",
          Logo: Logo || "",
          Music: Music || "",
          Facebook: Facebook || "",
          Twitter: Twitter || "",
          Instagram: Instagram || "",
          Youtube: Youtube || "",
          ErrorMsg: null,
        });

        if (Logo) setLogoSrc(Logo);
        if (Music) setMusicSrc(Music);
      } catch (error) {
        console.error("Error fetching data", error);
        setFormData((prevFormData) => ({
          ...prevFormData,
          ErrorMsg: "Error fetching settings data",
        }));
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (formData.Logo && typeof formData.Logo === "string") {
      setLogoSrc(formData.Logo);
    }
    if (formData.Music && typeof formData.Music === "string") {
      setMusicSrc(formData.Music);
    }
  }, [formData.Logo, formData.Music]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: file,
    }));

    if (file) {
      const fileUrl = URL.createObjectURL(file);
      if (name === "Logo") setLogoSrc(fileUrl);
      if (name === "Music") setMusicSrc(fileUrl);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let logoUrl = formData.Logo;
    if (typeof formData.Logo !== "string") {
      const imageData = new FormData();
      imageData.append("Image", formData.Logo);

      const imageUploadResponse = await axiosInstance.post(
        "/admin/upload-image",
        imageData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      logoUrl = imageUploadResponse.data.result; // Adjust based on your API response structure
    }

    let MusicUrl = formData.Music;
    if (typeof formData.Music !== "string") {
      const imageData = new FormData();
      imageData.append("Music", formData.Music);

      const imageUploadResponse = await axiosInstance.post(
        "/admin/upload-music",
        imageData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      MusicUrl = imageUploadResponse.data.result; // Adjust based on your API response structure
    }

    console.log("logoUrl", logoUrl);
    console.log("MusicUrl", MusicUrl);
    try {
      setIsLoading(true);
      const response = await axiosInstance.put("/admin/settings", {
        Email: formData.Email,
        Phone: formData.Phone,
        Working_Time: formData.Working_Time,
        Facebook: formData.Facebook,
        Instagram: formData.Instagram,
        Twitter: formData.Twitter,
        Youtube: formData.Youtube,
        Logo: logoUrl,
        Music: MusicUrl,
      });

      console.log("Update successful", response.data);
      setFormData((prevFormData) => ({
        ...prevFormData,
        ErrorMsg: "Settings updated successfully",
        Logo: logoUrl,
        Music: MusicUrl,
      }));
    } catch (error) {
      console.error("Error updating settings", error);
      setFormData((prevFormData) => ({
        ...prevFormData,
        ErrorMsg: "Error updating settings",
      }));
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loader />;

  return (
    <div className="maintopmargin" >
      <div className="content-wrapper">
        {/* <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Settings</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Settings</li>
                </ol>
              </div>
            </div>
          </div>
        </section> */}

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Settings</h3>
                  </div>
                  <form role="form" onSubmit={handleSubmit}>
                    <div className="card-body">
                      {/* Removed Title and Description fields from form */}

                      <div className="form-group">
                        <label htmlFor="Email">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          name="Email"
                          placeholder="Enter Email"
                          required
                          value={formData.Email}
                          onChange={handleInputChange}
                          aria-label="Email"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="Phone">Phone</label>
                        <input
                          type="text"
                          className="form-control"
                          name="Phone"
                          placeholder="Enter Phone"
                          required
                          value={formData.Phone}
                          onChange={handleInputChange}
                          aria-label="Phone"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="Working_Time">Working Time</label>
                        <input
                          type="text"
                          className="form-control"
                          name="Working_Time"
                          placeholder="Enter Working Time"
                          required
                          value={formData.Working_Time}
                          onChange={handleInputChange}
                          aria-label="Working Time"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="Facebook">Facebook</label>
                        <input
                          type="text"
                          className="form-control"
                          name="Facebook"
                          placeholder="Enter Facebook"
                          required
                          value={formData.Facebook}
                          onChange={handleInputChange}
                          aria-label="Facebook"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="Twitter">Twitter</label>
                        <input
                          type="text"
                          className="form-control"
                          name="Twitter"
                          placeholder="Enter Twitter"
                          required
                          value={formData.Twitter}
                          onChange={handleInputChange}
                          aria-label="Twitter"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="Instagram">Instagram</label>
                        <input
                          type="text"
                          className="form-control"
                          name="Instagram"
                          placeholder="Enter Instagram"
                          required
                          value={formData.Instagram}
                          onChange={handleInputChange}
                          aria-label="Instagram"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="Youtube">Youtube</label>
                        <input
                          type="text"
                          className="form-control"
                          name="Youtube"
                          placeholder="Enter Youtube"
                          required
                          value={formData.Youtube}
                          onChange={handleInputChange}
                          aria-label="Youtube"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="Logo">Logo</label>
                        <input
                          type="file"
                          className="form-control"
                          name="Logo"
                          accept="image/*"
                          onChange={handleFileChange}
                          aria-label="Logo"
                        />
                        {logoSrc && (
                          <div>
                            <img src={logoSrc} alt="Logo" width="200" />
                          </div>
                        )}
                      </div>

                      <div className="form-group">
                        <label htmlFor="Music">Music</label>
                        <input
                          type="file"
                          className="form-control"
                          name="Music"
                          accept="audio/*"
                          onChange={handleFileChange}
                          aria-label="Music"
                        />
                        {musicSrc && (
                          <div>
                            <audio controls>
                              <source src={musicSrc} type="audio/mpeg" />
                              Your browser does not support the audio element.
                            </audio>
                          </div>
                        )}
                      </div>

                      {formData.ErrorMsg && (
                        <p style={{ color: "black" }}>{formData.ErrorMsg}</p>
                      )}
                    </div>

                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
