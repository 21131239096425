import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import Header from "../includes/Header";
import Sidebar from "../includes/Sidebar";
import Footer from "../includes/Footer";
import Loader from "../Loader";

export default function CouponsForm() {
  const [formData, setFormData] = useState({
    Code: "",
    PackageId: "",
    Type: "",
    OfferType: "",
    DiscountAmount: "",
    Category: "", // New field
    ErrorMsg: null,
  });
  const [packages, setPackages] = useState([]);
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("id");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          setIsLoading(true);
          const response = await axiosInstance.get("/payment/coupon/" + id);
          const { Code, Type, OfferType, DiscountAmount, PackageId, Category } =
            response.data.result;
          setFormData({
            Code: Code || "",
            PackageId: PackageId || "",
            Type: Type || "",
            OfferType: OfferType || "",
            DiscountAmount: DiscountAmount || "",
            Category: Category || "", // Set the Category field
            ErrorMsg: null,
          });
        } catch (error) {
          console.error("Error fetching data", error);
          setFormData((prevFormData) => ({
            ...prevFormData,
            ErrorMsg: "Error fetching package data",
          }));
        } finally {
          setIsLoading(false);
        }
      }
    };

    const fetchPackages = async () => {
      try {
        setIsLoading(true);
        const response = await axiosInstance.get("/package");
        setPackages(response.data.result); // Adjust this based on your API response structure
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    fetchPackages();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let response;
      if (id) {
        setIsLoading(true);
        response = await axiosInstance.put(`/payment/coupon/${id}`, formData);
      } else {
        response = await axiosInstance.post(`/payment/coupon`, formData);
      }

      setFormData((prevFormData) => ({
        ...prevFormData,
        ErrorMsg: "Coupon data saved successfully",
      }));
      navigate("/admin/coupons");
    } catch (error) {

      setFormData((prevFormData) => ({
        ...prevFormData,
        ErrorMsg: error.response.data.error,
      }));

    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loader />;

  return (
    <div className="maintopmargin" >
      <div className="content-wrapper">
        {/* <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Coupons</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/coupons">Coupons List</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {id ? "Update Coupon" : "Create Coupon"}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section> */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">
                      {id ? "Update Coupon" : "Create Coupon"}
                    </h3>
                  </div>
                  <form role="form" onSubmit={handleSubmit}>
                    <div className="card-body">
                      {formData.ErrorMsg && (
                        <p style={{ color: "red" }}>{formData.ErrorMsg}</p>
                      )}
                      <div className="row">
                        <div className="col-lg-4">
                          <label htmlFor="Category">Category</label>
                          <select
                            className="custom-select"
                            name="Category"
                            value={formData.Category}
                            onChange={handleInputChange}
                            aria-label="Category"
                          >
                            <option value="">Select Category</option>
                            <option value="SIMPLE">SIMPLE</option>
                            <option value="COLLABORATOR">COLLABORATOR</option>
                          </select>
                        </div>

                        <div className="col-lg-4">
                          <label htmlFor="OfferType">Offer Type</label>
                          <select
                            className="custom-select"
                            name="OfferType"
                            value={formData.OfferType}
                            onChange={handleInputChange}
                            aria-label="OfferType"
                          >
                            <option value="">Select Type</option>
                            <option value="COUPON">COUPON</option>
                            <option value="REFERRAL">REFERRAL</option>
                          </select>
                        </div>

                        {formData.OfferType === "COUPON" && (
                          <div className="col-lg-4">
                            <label htmlFor="Code">Code</label>
                            <input
                              type="text"
                              className="form-control"
                              name="Code"
                              placeholder="Enter Code"
                              required
                              value={formData.Code}
                              onChange={handleInputChange}
                              aria-label="Code"
                            />
                          </div>
                        )}
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-lg-4">
                          <label htmlFor="PackageId">Package</label>
                          <select
                            className="custom-select"
                            name="PackageId"
                            value={formData.PackageId}
                            onChange={handleInputChange}
                            aria-label="PackageId"
                          >
                            <option value="">Select Package</option>
                            {packages.map((pkg) => (
                              <option key={pkg._id} value={pkg._id}>
                                {pkg.Name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="col-lg-4">
                          <label htmlFor="Type">Type</label>
                          <select
                            className="custom-select"
                            name="Type"
                            value={formData.Type}
                            onChange={handleInputChange}
                            aria-label="Type"
                          >
                            <option value="">Select Type</option>
                            <option value="PERCENTAGE">Percentage</option>
                            <option value="FIXED">Fixed</option>
                          </select>
                        </div>

                        <div className="col-lg-4">
                          <label htmlFor="DiscountAmount">
                            Discount Percentage
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            name="DiscountAmount"
                            placeholder="Enter Discount Value"
                            required
                            value={formData.DiscountAmount}
                            onChange={handleInputChange}
                            aria-label="DiscountAmount"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        {id ? "Update" : "Create"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
