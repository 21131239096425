import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../axiosInstance";
import Header from "./includes/Header";
import Sidebar from "./includes/Sidebar";
import Footer from "./includes/Footer";
import Loader from "./Loader";

export default function AboutUs() {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    Title1: "",
    Title2: "",
    Heading_1: "",
    Image_1: null,
    Image_1_URL: null, // for preview
    Heading_2: "",
    Image_2: null,
    Image_2_URL: null, // for preview
    Description_1: "",
    Description_2: "",
    Banner: null,
    Banner_URL: null, // for preview
    ErrorMsg: null,
    SuccessMsg: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axiosInstance.get(`/admin/about`);
        console.log(response.data.result);

        const {
          Title1,
          Title2,
          Heading_1,
          Image_1,
          Heading_2,
          Image_2,
          Description_1,
          Description_2,
          Banner,
        } = response.data.result;
        setFormData({
          Title1: Title1 || "",
          Title2: Title2 || "",
          Heading_1: Heading_1 || "",
          Image_1: Image_1 || null,
          Image_1_URL: Image_1 || null,
          Heading_2: Heading_2 || "",
          Image_2: Image_2 || null,
          Image_2_URL: Image_2 || null,
          Description_1: Description_1 || "",
          Description_2: Description_2 || "",
          Banner: Banner || null,
          Banner_URL: Banner || null,
          ErrorMsg: null,
          SuccessMsg: null,
        });
      } catch (error) {
        console.error("Error fetching data", error);
        setFormData((prevFormData) => ({
          ...prevFormData,
          ErrorMsg: "Error fetching data",
          SuccessMsg: null,
        }));
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: file,
      [`${name}_URL`]: URL.createObjectURL(file),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let image1Url = formData.Image_1;
    let image2Url = formData.Image_2;
    let bannerUrl = formData.Banner;

    if (typeof formData.Image_1 !== "string") {
      const imageData = new FormData();
      imageData.append("Image", formData.Image_1);

      const imageUploadResponse = await axiosInstance.post(
        "/admin/upload-image",
        imageData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      image1Url = imageUploadResponse.data.result; // Adjust based on your API response structure
    }
    if (typeof formData.Image_2 !== "string") {
      const imageData2 = new FormData();
      imageData2.append("Image", formData.Image_2);

      const image2UploadResponse = await axiosInstance.post(
        "/admin/upload-image",
        imageData2,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      image2Url = image2UploadResponse.data.result; // Adjust based on your API response structure
    }
    if (typeof formData.Banner !== "string") {
      const bannerData = new FormData();
      bannerData.append("Image", formData.Banner);

      const bannerUploadResponse = await axiosInstance.post(
        "/admin/upload-image",
        bannerData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      bannerUrl = bannerUploadResponse.data.result; // Adjust based on your API response structure
    }

    try {
      setIsLoading(true);
      await axiosInstance.put("/admin/about", {
        Title1: formData.Title1,
        Title2: formData.Title2,
        Image_1: image1Url,
        Image_2: image2Url,
        Heading_1: formData.Heading_1,
        Heading_2: formData.Heading_2,
        Description_1: formData.Description_1,
        Description_2: formData.Description_2,
        Banner: bannerUrl,
      });

      setFormData((prevFormData) => ({
        ...prevFormData,
        SuccessMsg: "About Us updated successfully!",
        ErrorMsg: null,
      }));
    } catch (error) {
      console.error("Error saving data", error);
      setFormData((prevFormData) => ({
        ...prevFormData,
        ErrorMsg: "Error saving data",
        SuccessMsg: null,
      }));
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loader />;
  return (
    <div className="maintopmargin">
      <div className="content-wrapper">
        {/* <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>About Us</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active"> About Us</li>
                </ol>
              </div>
            </div>
          </div>
        </section> */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">About Us</h3>
                  </div>
                  <form
                    role="form"
                    onSubmit={handleSubmit}
                    encType="multipart/form-data"
                  >
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-6">
                          <label htmlFor="Title1">Title 1</label>
                          <input
                            type="text"
                            className="form-control"
                            name="Title1"
                            placeholder="Enter Title 1"
                            required
                            value={formData.Title1}
                            onChange={handleInputChange}
                            aria-label="Title1"
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="Title2">Title 2</label>
                          <input
                            type="text"
                            className="form-control"
                            name="Title2"
                            placeholder="Enter Title 2"
                            required
                            value={formData.Title2}
                            onChange={handleInputChange}
                            aria-label="Title2"
                          />
                        </div>
                      </div>
                      <br />
                      <div className="col-lg-6">
                        <label htmlFor="Banner">Banner</label>
                        <input
                          type="file"
                          className="form-control"
                          name="Banner"
                          onChange={handleFileChange}
                          aria-label="Banner"
                        />
                        {formData.Banner_URL && (
                          <div style={{ marginTop: "10px" }}>
                            <img
                              src={formData.Banner_URL}
                              alt="Banner"
                              style={{ width: "100%", maxWidth: "200px" }}
                            />
                          </div>
                        )}
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-lg-6">
                          <label htmlFor="Heading_1">Heading 1</label>
                          <input
                            type="text"
                            className="form-control"
                            name="Heading_1"
                            placeholder="Enter Heading 1"
                            required
                            value={formData.Heading_1}
                            onChange={handleInputChange}
                            aria-label="Heading_1"
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="Image_1">Image 1</label>
                          <input
                            type="file"
                            className="form-control"
                            name="Image_1"
                            onChange={handleFileChange}
                            aria-label="Image_1"
                          />
                          {formData.Image_1_URL && (
                            <div style={{ marginTop: "10px" }}>
                              <img
                                src={formData.Image_1_URL}
                                alt="Image 1"
                                style={{ width: "100%", maxWidth: "200px" }}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <br />
                      <div className="row">
                        <div className="col-lg-6">
                          <label htmlFor="Heading_2">Heading 2</label>
                          <input
                            type="text"
                            className="form-control"
                            name="Heading_2"
                            placeholder="Enter Heading 2"
                            required
                            value={formData.Heading_2}
                            onChange={handleInputChange}
                            aria-label="Heading_2"
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="Image_2">Image 2</label>
                          <input
                            type="file"
                            className="form-control"
                            name="Image_2"
                            onChange={handleFileChange}
                            aria-label="Image_2"
                          />
                          {formData.Image_2_URL && (
                            <div style={{ marginTop: "10px" }}>
                              <img
                                src={formData.Image_2_URL}
                                alt="Image 2"
                                style={{ width: "100%", maxWidth: "200px" }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <br />
                      <div className="col-lg-12">
                        <label htmlFor="Description_1">Description 1</label>
                        <textarea
                          className="textarea"
                          placeholder="Enter Description 1"
                          style={{
                            width: "100%",
                            height: "200px",
                            fontSize: "14px",
                            lineHeight: "18px",
                            border: "1px solid #dddddd",
                            padding: "10px",
                          }}
                          name="Description_1"
                          value={formData.Description_1}
                          onChange={handleInputChange}
                        ></textarea>
                      </div>
                      <br />
                      <div className="col-lg-12">
                        <label htmlFor="Description_2">Description 2</label>
                        <textarea
                          className="textarea"
                          placeholder="Enter Description 2"
                          style={{
                            width: "100%",
                            height: "200px",
                            fontSize: "14px",
                            lineHeight: "18px",
                            border: "1px solid #dddddd",
                            padding: "10px",
                          }}
                          name="Description_2"
                          value={formData.Description_2}
                          onChange={handleInputChange}
                        ></textarea>
                      </div>

                      {formData.ErrorMsg && (
                        <p style={{ color: "red" }}>{formData.ErrorMsg}</p>
                      )}
                      {formData.SuccessMsg && (
                        <p style={{ color: "green" }}>{formData.SuccessMsg}</p>
                      )}
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
