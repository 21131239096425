import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import Header from "../includes/Header";
import Sidebar from "../includes/Sidebar";
import Footer from "../includes/Footer";
import Loader from "../Loader";

export default function PackagesUpdate() {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    Type: "",
    Name: "",
    Validity: "",
    Amount: "",
    RequestPerDay: "",
    Description: [],
    ErrorMsg: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      const queryParameters = new URLSearchParams(window.location.search);
      const id = queryParameters.get("id");

      try {
        setIsLoading(true);
        const response = await axiosInstance.get("/package/" + id);
        const { Type, Name, Validity, Amount, RequestPerDay, Description } =
          response.data.result;
        setFormData({
          Type: Type || "",
          Name: Name || "",
          Validity: Validity || "",
          Amount: Amount || "",
          RequestPerDay: RequestPerDay || "",
          Description: Description || [],
          ErrorMsg: null,
        });
      } catch (error) {
        console.error("Error fetching data", error);
        setFormData((prevFormData) => ({
          ...prevFormData,
          ErrorMsg: "Error fetching package data",
        }));
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleDescriptionChange = (index, field, value) => {
    setFormData((prevFormData) => {
      let newDescription = [...prevFormData.Description];
      newDescription[index] = { ...newDescription[index], [field]: value };
      if (newDescription[index].Label === "") {
        newDescription = newDescription.filter((_, i) => i !== index);
      }

      return {
        ...prevFormData,
        Description: newDescription,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const queryParameters = new URLSearchParams(window.location.search);
    const id = queryParameters.get("id");

    try {
      setIsLoading(true);
      const response = await axiosInstance.put(`/package/${id}`, formData);
      console.log("Update successful", response.data);
      setFormData((prevFormData) => ({
        ...prevFormData,
        ErrorMsg: "Package data updated successfully",
      }));
    } catch (error) {
      console.error("Error updating package", error);
      setFormData((prevFormData) => ({
        ...prevFormData,
        ErrorMsg: "Error updating package: " + error.message,
      }));
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loader />;

  return (
    <div className="maintopmargin" >
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Packages</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/packages">Package List</Link>
                  </li>
                  <li className="breadcrumb-item active">Packages</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Package</h3>
                  </div>
                  <form role="form" onSubmit={handleSubmit}>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-4">
                          <label htmlFor="Type">Type</label>
                          <select
                            className="custom-select"
                            name="Type"
                            value={formData.Type}
                            onChange={handleInputChange}
                            aria-label="Type"
                          >
                            <option value="">Select Type</option>
                            <option value="User">User</option>
                            <option value="Advertising">Advertising</option>
                          </select>
                        </div>
                        <div className="col-lg-4">
                          <label htmlFor="Name">Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="Name"
                            placeholder="Enter Name"
                            required
                            value={formData.Name}
                            onChange={handleInputChange}
                            aria-label="Name"
                          />
                        </div>
                        <div className="col-lg-4">
                          <label htmlFor="Validity">Validity</label>
                          <input
                            type="number"
                            className="form-control"
                            name="Validity"
                            placeholder="Enter Validity"
                            required
                            value={formData.Validity}
                            onChange={handleInputChange}
                            aria-label="Validity"
                          />
                        </div>
                      </div>
                      <br />
                      {formData.Type !== "Advertising" && (
                        <>
                          <div className="row">
                            <div className="col-lg-4">
                              <label htmlFor="Amount">Amount</label>
                              <input
                                type="number"
                                className="form-control"
                                name="Amount"
                                placeholder="Enter Amount"
                                required
                                value={formData.Amount}
                                onChange={handleInputChange}
                                aria-label="Amount"
                              />
                            </div>
                            <div className="col-lg-4">
                              <label htmlFor="RequestPerDay">
                                Requests Per Day
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                name="RequestPerDay"
                                placeholder="Enter Requests Per Day"
                                required
                                value={formData.RequestPerDay}
                                onChange={handleInputChange}
                                aria-label="RequestPerDay"
                              />
                            </div>
                          </div>
                          <br />
                        </>
                      )}
                      <div className="row">
                        {[...Array(6)].map((_, index) => (
                          <div className="col-lg-6" key={index}>
                            <label htmlFor={`label${index + 1}`}></label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <input
                                    type="checkbox"
                                    name={`status${index + 1}`}
                                    checked={
                                      formData.Description[index]?.Status ||
                                      false
                                    }
                                    onChange={(e) =>
                                      handleDescriptionChange(
                                        index,
                                        "Status",
                                        e.target.checked
                                      )
                                    }
                                  />
                                </span>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                name={`label${index + 1}`}
                                value={
                                  formData.Description[index]?.Label || ""
                                }
                                onChange={(e) =>
                                  handleDescriptionChange(
                                    index,
                                    "Label",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        ))}
                      </div>

                      {formData.ErrorMsg && (
                        <p style={{ color: "green" }}>{formData.ErrorMsg}</p>
                      )}
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
