import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoutes = () => {
  const auth = localStorage.getItem("auth");
  const password = localStorage.getItem("password");

  if (auth && password) return <Outlet />;
  return <div>{<Navigate to="/login" replace />}</div>;
};

export default ProtectedRoutes;
