export default function Links() {
  return (
    <>
      <link
        rel="stylesheet"
        href="https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css"
      />
      <link rel="stylesheet" href="../dist/css/adminlte.min.css" />
      <link
        rel="stylesheet"
        href="../plugins/icheck-bootstrap/icheck-bootstrap.min.css"
      ></link>
      <link rel="stylesheet" href="../dist/css/login.css"></link>

      <link
        href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700"
        rel="stylesheet"
      />

      <script src="../plugins/jquery/jquery.min.js" />
      <script src="../plugins/bootstrap/js/bootstrap.bundle.min.js" />

      <script src="../dist/js/adminlte.min.js" />
      <script src="../dist/js/adminlte.js" />
      <script src="../dist/js/demo.js" />
    </>
  );
}
