import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import Footer from "../includes/Footer";
import Loader from "../Loader";

export default function BlogUpdate() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    Title: "",
    Image: null,
    ImageUrl: "", // Add ImageUrl state to handle image display
    Content: "",
    ErrorMsg: null,
  });
  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("id");

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          setIsLoading(true);
          const response = await axiosInstance.get(`/blog/single/${id}`);
          const { Title, Image, Content } = response.data.result;
          setFormData({
            Title: Title || "",
            Image: null, // Set to null since the file input will not display the image
            ImageUrl: Image || "", // Set ImageUrl to display the image
            Content: Content || "",
            ErrorMsg: null,
          });
        } catch (error) {
          console.error("Error fetching data", error);
          setFormData((prevFormData) => ({
            ...prevFormData,
            ErrorMsg: "Error fetching blog data",
          }));
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files && files[0]) {
      const file = files[0];
      const imageUrl = URL.createObjectURL(file);
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: file,
        ImageUrl: imageUrl,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const blogData = new FormData();
    blogData.append("Title", formData.Title);
    if (formData.Image) blogData.append("Image", formData.Image);
    blogData.append("Content", formData.Content);

    let image1Url = formData.Image;
    if (typeof formData.Image !== "string" && image1Url !== null) {
      const imageData = new FormData();
      imageData.append("Image", formData.Image);

      const imageUploadResponse = await axiosInstance.post(
        "/admin/upload-image",
        imageData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      image1Url = imageUploadResponse.data.result; // Adjust based on your API response structure
    }

    try {
      if (id) {
        setIsLoading(true);
        await axiosInstance.put(`/blog/${id}`, {
          Title: formData.Title,
          Content: formData.Content,
          Image: image1Url || formData.ImageUrl
        });
      } else {
        await axiosInstance.post("/blog", blogData);
      }
      navigate("/admin/blogs");
    } catch (error) {
      console.error("Error saving blog", error);
      setFormData((prevFormData) => ({
        ...prevFormData,
        ErrorMsg: "Error saving blog data",
      }));
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loader />;

  return (
    <div className="maintopmargin" >
      <div className="content-wrapper">
        {/* <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Blogs</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/blogs">Blogs List</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {id ? "Update Blog" : "Create Blog"}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section> */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">
                      {id ? "Update Blog" : "Create Blog"}
                    </h3>
                  </div>
                  <form
                    role="form"
                    onSubmit={handleSubmit}
                    encType="multipart/form-data"
                  >
                    <div className="card-body">
                      {formData.ErrorMsg && (
                        <p style={{ color: "red" }}>{formData.ErrorMsg}</p>
                      )}
                      <div className="row">
                        <div className="col-lg-4">
                          <label htmlFor="Title">Title</label>
                          <input
                            type="text"
                            className="form-control"
                            name="Title"
                            placeholder="Enter Title"
                            required
                            value={formData.Title}
                            onChange={handleInputChange}
                            aria-label="Title"
                          />
                        </div>
                        <div className="col-lg-4">
                          <label htmlFor="Image">Image</label>
                          <input
                            type="file"
                            className="form-control"
                            name="Image"
                            onChange={handleFileChange}
                            aria-label="Image"
                          />
                          {formData.ImageUrl && (
                            <div style={{ marginTop: "10px" }}>
                              <img
                                src={formData.ImageUrl}
                                alt="Blog"
                                width="100"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <br />
                      <div className="col-lg-12">
                        <label htmlFor="Content">Description</label>
                        <textarea
                          className="textarea"
                          placeholder="Enter Content"
                          style={{
                            width: "100%",
                            height: "200px",
                            fontSize: "14px",
                            lineHeight: "18px",
                            border: "1px solid #dddddd",
                            padding: "10px",
                          }}
                          name="Content"
                          value={formData.Content}
                          onChange={handleInputChange}
                        ></textarea>
                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        {id ? "Update" : "Create"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
