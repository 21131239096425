import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import Header from "../includes/Header";
import Sidebar from "../includes/Sidebar";
import Footer from "../includes/Footer";
import Loader from "../Loader";

export default function StoryUpdate() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    Name: "",
    Image: null,
    Message: "",
    ErrorMsg: null,
  });
  const [previewImage, setPreviewImage] = useState(null); // State for image preview
  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("id");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id) {
      const fetchStory = async () => {
        try {
          setIsLoading(true);
          const response = await axiosInstance.get(`/story/single/${id}`);
          const { Name, Image, Message } = response.data.result;
          setFormData({
            Name: Name || "",
            Image: Image || null,
            Message: Message || "",
            ErrorMsg: null,
          });
          setPreviewImage(Image || null); // Set preview image if exists
        } catch (error) {
          console.error("Error fetching story", error);
          setFormData((prevFormData) => ({
            ...prevFormData,
            ErrorMsg: "Error fetching story data",
          }));
        } finally {
          setIsLoading(false);
        }
      };
      fetchStory();
    }
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files[0]) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: files[0],
      }));
      setPreviewImage(URL.createObjectURL(files[0])); // Set preview image URL
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const blogData = new FormData();
    blogData.append("Name", formData.Name);
    if (formData.Image) blogData.append("Image", formData.Image);
    blogData.append("Message", formData.Message);

    try {
      if (id) {
        setIsLoading(true);
        let image1Url = formData.Image;
        if (typeof formData.Image !== "string") {
          const imageData = new FormData();
          imageData.append("Image", formData.Image);

          const imageUploadResponse = await axiosInstance.post(
            "/admin/upload-image",
            imageData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          image1Url = imageUploadResponse.data.result; // Adjust based on your API response structure
        }
        // Update existing story
        await axiosInstance.put(`/story/${id}`, {
          Name: formData.Name,
          Message: formData.Message,
          Image: image1Url,
        });
      } else {
        // Create new story
        await axiosInstance.post("/story", blogData);
      }
      navigate("/admin/stories");
    } catch (error) {
      console.error("Error saving story", error);
      setFormData((prevFormData) => ({
        ...prevFormData,
        ErrorMsg: "Error saving story data",
      }));
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loader />;
  return (
    <div className="maintopmargin" >
      <div className="content-wrapper">
        {/* <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Blogs</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/blogs">Blogs List</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {id ? "Update Blog" : "Create Blog"}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section> */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">
                      {id ? "Update Blog" : "Create Blog"}
                    </h3>
                  </div>
                  <form
                    role="form"
                    onSubmit={handleSubmit}
                    encType="multipart/form-data"
                  >
                    <div className="card-body">
                      {formData.ErrorMsg && (
                        <p style={{ color: "red" }}>{formData.ErrorMsg}</p>
                      )}
                      <div className="row">
                        <div className="col-lg-4">
                          <label htmlFor="Name">Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="Name"
                            placeholder="Enter Name"
                            required
                            value={formData.Name}
                            onChange={handleInputChange}
                            aria-label="Name"
                          />
                        </div>
                        <div className="col-lg-4">
                          <label htmlFor="Image">Image</label>
                          <input
                            type="file"
                            className="form-control"
                            name="Image"
                            onChange={handleFileChange}
                            aria-label="Image"
                          />
                          {previewImage && (
                            <img
                              src={previewImage}
                              alt="Preview"
                              style={{ width: "100px", marginTop: "10px" }}
                            />
                          )}
                        </div>
                        <div className="col-lg-4"></div>
                        <div className="col-lg-12">
                          <label htmlFor="Message">Message</label>
                          <textarea
                            className="Message"
                            placeholder="Enter Message"
                            style={{
                              width: "100%",
                              height: "200px",
                              fontSize: "14px",
                              lineHeight: "18px",
                              border: "1px solid #dddddd",
                              padding: "10px",
                            }}
                            name="Message"
                            value={formData.Message}
                            onChange={handleInputChange}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        {id ? "Update" : "Create"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
