import { useEffect, useState } from "react";
import axiosInstance from "../../axiosInstance";
import Footer from "../includes/Footer";
import { Modal, Button } from "react-bootstrap";
import Loader from "../Loader";
const fmt = require("indian-number-format");

export default function UsersDetails() {
  const [formData, setFormData] = useState({
    _id: "",
    Aadhar: "",
    Aboutme: "",
    Additional: {},
    CurrentAddress: {},
    EducationWork: { Education: {}, Work: {} },
    Email: "",
    Family: {},
    GovtCert: "",
    OfferLetter: "",
    PAN: "",
    Passport: "",
    PermanentAddress: {},
    Photos: [],
    Personal: {},
    ProfileFor: "",
    Refill_Token: "",
    Username: "",
    LastIp: "",
    LastSession: "",
    PartnerPreference: "",
    CreatedAt: "",
    Status: "",
    Video: "",
    ErrorMsg: null,
  });

  const [selectedImage, setSelectedImage] = useState(null); // State to store the clicked image details
  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("id");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axiosInstance.get("/admin/user/" + id);
        console.log(response.data.result);
        const result = response.data.result;
        setFormData({
          _id: result._id || "",
          Aadhar: result.Aadhar || "",
          Aboutme: result.Aboutme || "",
          Additional: result.Additional || {},
          CurrentAddress: result.CurrentAddress || {},
          EducationWork: result.EducationWork || { Education: {}, Work: {} },
          Email: result.Email || "",
          Family: result.Family || {},
          GovtCert: result.GovtCert || "",
          OfferLetter: result.OfferLetter || "",
          PAN: result.PAN || "",
          Passport: result.Passport || "",
          PermanentAddress: result.PermanentAddress || {},
          Photos: result.Photos || [],
          Personal: result.Personal || {},
          ProfileFor: result.ProfileFor || "",
          Refill_Token: result.Refill_Token || "",
          Username: result.Username || "",
          LastIp: result.LastIp || "",
          LastSession: result.LastSession || "",
          PartnerPreference: result.PartnerPreference || "",
          CreatedAt: result.CreatedAt || "",
          Status: result.Status || "",
          Video: result.Video || "",
          ErrorMsg: null,
        });
      } catch (error) {
        console.error("Error fetching data", error);
        setFormData((prevFormData) => ({
          ...prevFormData,
          ErrorMsg: "Error fetching package data",
        }));
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id]);
  function formatDateTime(dateString) {
    const date = new Date(dateString);
    return date.toLocaleString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }

  const handleDelete = async (id, key) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this document?"
    );
    if (confirmDelete) {
      try {
        setIsLoading(true);
        let keyObj = {};
        keyObj[key] = "";

        await axiosInstance.put(`/admin/user/${id}`, keyObj);
        setFormData((prevState) => ({
          ...prevState,
          [key]: "",
        }));
      } catch (error) {
        console.error("Error deleting document", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  const [status, setStatus] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Show a confirmation alert
    const isConfirmed = window.confirm(
      "Are you sure you want to update the status?"
    );
    if (isConfirmed) {
      try {
        setIsLoading(true);
        // Show an alert with the selected status
        await axiosInstance.put(`/admin/user-status/${id}`, {
          Status: status,
          Email: formData.Email,
        });

        setFormData((prevState) => ({
          ...prevState,
          Status: status,
        }));

        if (status != "INVALID") {
          alert("Status Updated Successfully");
        } else {
          alert(
            "Email has been sent successfully to reupload the invalid documents"
          );
        }
      } catch (error) {
        console.error("Error deleting document", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  if (isLoading) return <Loader />;

  const copyToClipboard = async (text) => {
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(text);
        alert('Text copied to clipboard');
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
    } else {
      // Fallback method for non-secure contexts
      const textarea = document.createElement('textarea');
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand('copy');
        alert('Text copied to clipboard');
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
      document.body.removeChild(textarea);
    }
  };

  return (
    <div>
      <div className="content-wrapper user-detail">
        {/* <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6 alignItemCenter">
                <h1>Profile</h1>
              </div>
              <div className="col-sm-2">              
                 
              </div>
            </div>
          </div>
        </section> */}

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3">
                <div className="card card-primary card-outline">
                  <div className="card-body box-profile">
                    <div className="text-center">
                      <img
                        className="profile-user-img img-fluid img-circle"
                        src={formData?.Photos[0]?.["25px"]}
                        alt="User profile"
                      />
                    </div>
                    <h3 className="profile-username text-center">
                      {formData.Personal?.FirstName}{" "}
                      {formData.Personal?.LastName}
                    </h3>
                    <p className="text-muted text-center">
                      Nickname: {formData.Additional?.NickName}
                    </p>
                    <ul className="list-group list-group-unbordered mb-3">
                      <li className="list-group-item">
                        <b>Username</b>{" "}
                        <a className="float-right">{formData.Username} <a href="#" onClick={() => copyToClipboard(formData.Username)}>Copy</a></a>
                      </li>
                      <li className="list-group-item">
                        <b>Status</b>{" "}
                        <a className="float-right">{formData.Status}</a>
                      </li>
                      <li className="list-group-item">
                        <b>Email</b>{" "}
                        <a className="float-right">{formData.Email}</a>
                      </li>

                      <li className="list-group-item">
                        <b>Profile For</b>{" "}
                        <a className="float-right">{formData.ProfileFor}</a>
                      </li>

                      <li className="list-group-item">
                        <b>Partner Preference</b>{" "}
                        <a className="float-right">
                          {formData.PartnerPreference}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Other Info</h3>
                  </div>
                  <div className="card-body">
                    <strong>CreatedAt</strong>
                    <p className="text-muted">
                      {formatDateTime(formData.CreatedAt)}
                    </p>
                    <hr />
                    <strong>Last Ip</strong>
                    <p className="text-muted">{formData.LastIp}</p>
                    <hr />
                    <strong>Last Session</strong>
                    <p className="text-muted">
                      {formatDateTime(formData.LastSession)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div className="card">
                  <div className="card-body">
                    <h2>
                      <b style={{ color: "#be3064" }}>Validate Profile</b>
                    </h2>

                    <form role="form" onSubmit={handleSubmit}>
                      <div className="card-body pd0">
                        <div className="row">
                          <div className="col-lg-4">
                            <select
                              className="form-control"
                              value={status == "" ? formData.Status : status}
                              onChange={(e) => setStatus(e.target.value)}
                            >
                              <option value="INVALID">INVALID</option>
                              <option value="ACTIVE">ACTIVE</option>
                              <option value="PENDING">PENDING</option>
                              <option value="HIDE">HIDE</option>
                              <option value="DISABLED">DISABLED</option>
                              <option value="DELETED">DELETED</option>
                            </select>
                          </div>
                          <div className="col-lg-4">
                            <button type="submit" className="btn btn-primary">
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>

                    <div className="tab-content">
                      <div className="active tab-pane" id="activity">
                        <div className="post">
                          <div className="row">
                            <div className="col-6">
                              <h2>
                                <b style={{ color: "#be3064" }}>About Me</b>
                              </h2>
                              {formData.Aboutme}
                            </div>

                            <div className="col-6">
                              <h2>
                                <b style={{ color: "#be3064" }}>Personal</b>
                              </h2>

                              <p className="text-muted text-sm">
                                <b>DOB: </b> {formatDate(formData.Personal.Dob)}{" "}
                              </p>
                              <p className="text-muted text-sm">
                                <b>Gender: </b> {formData.Personal.Gender}{" "}
                              </p>

                              <p className="text-muted text-sm">
                                <b>Community: </b> {formData.Personal.Community}{" "}
                              </p>

                              <p className="text-muted text-sm">
                                <b>Marital Status: </b>{" "}
                                {formData.Personal.MaritalStatus}{" "}
                              </p>

                              <p className="text-muted text-sm">
                                <b>Mother tongue: </b>{" "}
                                {formData.Personal.Mothertongue}{" "}
                              </p>
                              <p className="text-muted text-sm">
                                <b>Nationality: </b>{" "}
                                {formData.Personal.Nationality}{" "}
                              </p>

                              <p className="text-muted text-sm">
                                <b>Religion: </b> {formData.Personal.Religion}{" "}
                              </p>
                              <p className="text-muted text-sm">
                                <b>Height: </b> {formData.Personal.Height}{" "}
                              </p>
                              <p className="text-muted text-sm">
                                <b>Weight: </b> {formData.Personal.Weight}kg{" "}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="post clearfix">
                          <div className="row">
                            <div className="col-6">
                              <h2>
                                <b style={{ color: "#be3064" }}>Education</b>
                              </h2>
                              <p className="text-muted text-sm">
                                <b>College: </b>{" "}
                                {formData.EducationWork.Education?.CollegeName}{" "}
                              </p>
                              <p className="text-muted text-sm">
                                <b>Education: </b>{" "}
                                {
                                  formData.EducationWork.Education
                                    ?.EducationName
                                }{" "}
                              </p>
                              <p className="text-muted text-sm">
                                <b>Passing Year: </b>{" "}
                                {formData.EducationWork.Education?.PassingYear}{" "}
                              </p>
                            </div>
                            <div className="col-6">
                              <h2>
                                <b style={{ color: "#be3064" }}>Work</b>
                              </h2>
                              <p className="text-muted text-sm">
                                <b>Company: </b>{" "}
                                {formData.EducationWork.Work?.CompanyName}{" "}
                              </p>
                              <p className="text-muted text-sm">
                                <b>Exact Income: </b>{" "}
                                {fmt.format(formData.EducationWork.Work?.ExactIncome)}{" "}
                              </p>
                              <p className="text-muted text-sm">
                                <b>Occupation: </b>{" "}
                                {formData.EducationWork.Work?.Occupation}{" "}
                              </p>
                              <p className="text-muted text-sm">
                                <b>Work Title: </b>{" "}
                                {formData.EducationWork.Work?.WorkTitle}{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="post clearfix">
                          <div className="row">
                            <div className="col-6">
                              <h2>
                                <b style={{ color: "#be3064" }}>Family</b>
                              </h2>
                              <p className="text-muted text-sm">
                                <b>Family Type: </b>{" "}
                                {formData.Family?.FamilyType}{" "}
                              </p>
                              <p className="text-muted text-sm">
                                <b>Father Status: </b>{" "}
                                {formData.Family?.FatherStatus}{" "}
                              </p>
                              <p className="text-muted text-sm">
                                <b>Guardian Contact: </b>{" "}
                                {formData.Family?.GuardianContact}{" "}
                              </p>
                              <p className="text-muted text-sm">
                                <b>Mother Status: </b>{" "}
                                {formData.Family?.MotherStatus}{" "}
                              </p>
                              <p className="text-muted text-sm">
                                <b>Total Brothers: </b>{" "}
                                {formData.Family?.TotalBrothers}{" "}
                              </p>
                              <p className="text-muted text-sm">
                                <b>Total Sisters: </b>{" "}
                                {formData.Family?.TotalSisters}{" "}
                              </p>
                            </div>

                            <div className="col-6">
                              <h2>
                                <b style={{ color: "#be3064" }}>Additional</b>
                              </h2>
                              <p className="text-muted text-sm">
                                <b>Expectation: </b>{" "}
                                {formData.Additional?.Expectation}{" "}
                              </p>
                              <p className="text-muted text-sm">
                                <b>Interests: </b>{" "}
                              </p>
                              <ul>
                                {formData?.Additional?.Interest?.map(
                                  (Interest, index) => (
                                    <li className="text-muted text-sm">
                                      {Interest}
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div className="post clearfix">
                          <div className="row">
                            <div className="col-6">
                              <h2>
                                <b style={{ color: "#be3064" }}>
                                  Current Address
                                </b>
                              </h2>
                              <p className="text-muted text-sm">
                                <b>Address: </b>{" "}
                                {formData.CurrentAddress?.Address}{" "}
                              </p>
                              <p className="text-muted text-sm">
                                <b>City: </b> {formData.CurrentAddress?.City}
                              </p>
                              <p className="text-muted text-sm">
                                <b>State: </b> {formData.CurrentAddress?.State}{" "}
                              </p>
                              <p className="text-muted text-sm">
                                <b>Pincode: </b>{" "}
                                {formData.CurrentAddress?.Pincode}
                              </p>
                              <p className="text-muted text-sm">
                                <b>Friend Name: </b>{" "}
                                {formData.CurrentAddress?.FriendName}
                              </p>
                              <p className="text-muted text-sm">
                                <b>Friend Number: </b>{" "}
                                {formData.CurrentAddress?.FriendNumber}
                              </p>
                            </div>

                            <div className="col-6">
                              <h2>
                                <b style={{ color: "#be3064" }}>
                                  Permanent Address
                                </b>
                              </h2>
                              <p className="text-muted text-sm">
                                <b>Address: </b>{" "}
                                {formData.PermanentAddress?.Address}{" "}
                              </p>
                              <p className="text-muted text-sm">
                                <b>City: </b> {formData.PermanentAddress?.City}
                              </p>
                              <p className="text-muted text-sm">
                                <b>State: </b>{" "}
                                {formData.PermanentAddress?.State}{" "}
                              </p>
                              <p className="text-muted text-sm">
                                <b>Pincode: </b>{" "}
                                {formData.PermanentAddress?.Pincode}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="post">
                          <h2>
                            <b style={{ color: "#be3064" }}>Photos</b>
                          </h2>
                          <div className="row mb-3">
                            {formData?.Photos?.map((img, index) => (
                              <div className="col-sm-3" key={index}>
                                <img
                                  className="img-fluid myClassforPhoto"
                                  src={img["25px"]}
                                  alt="Photo"
                                  style={{ width: 200, height: 200 }}
                                  onClick={() => handleImageClick(img["75px"])}
                                />
                              </div>
                            ))}
                          </div>
                        </div>

                        <div className="post">
                          <h2>
                            <b style={{ color: "#be3064" }}>Video</b>
                          </h2>
                          <div>
                            {formData.Video ? (
                              <video width={400} height={300} controls>
                                <source src={formData.Video} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            ) : (
                              <p>Video source not available.</p>
                            )}
                          </div>
                        </div>

                        <div className="post">
                          <h2>
                            <b style={{ color: "#be3064" }}>Documents</b>
                          </h2>
                          <div className="row mb-3">
                            {formData.Aadhar && (
                              <>
                                <div
                                  className="col-sm-6"
                                  style={{ textAlign: "left" }}
                                >
                                  <p className="text-muted text-sm">
                                    <b>Aadhar:</b>
                                  </p>
                                  <img
                                    className="img-fluid"
                                    src={formData.Aadhar}
                                    alt="aadhar"
                                    style={{ width: 200, height: 200 }}
                                    onClick={() =>
                                      handleImageClick(formData.Aadhar)
                                    }
                                  />
                                  <div className="profileDocumentBlock">
                                    <button
                                      type="button"
                                      class="btn  btn-info btn-sm"
                                      style={{ width: "80px" }}
                                      onClick={() =>
                                        window.open(formData.Aadhar, "_blank")
                                      }
                                    >
                                      Download
                                    </button>
                                    &nbsp;
                                    <button
                                      type="button"
                                      class="btn  btn-danger btn-sm"
                                      style={{ width: "70px" }}
                                      onClick={() =>
                                        handleDelete(formData._id, "Aadhar")
                                      }
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                                <br />
                              </>
                            )}

                            {formData.PAN && (
                              <>
                                <div
                                  className="col-sm-6"
                                  style={{ textAlign: "center" }}
                                >
                                  <p className="text-muted text-sm">
                                    <b>PAN Card:</b>
                                  </p>
                                  <img
                                    className="img-fluid"
                                    src={formData.PAN}
                                    alt="PAN"
                                    style={{ width: 200, height: 200 }}
                                    onClick={() =>
                                      handleImageClick(formData.PAN)
                                    }
                                  />
                                  <div>
                                    <button
                                      type="button"
                                      class="btn  btn-info btn-sm"
                                      style={{ width: "80px" }}
                                      onClick={() =>
                                        window.open(formData.PAN, "_blank")
                                      }
                                    >
                                      Download
                                    </button>
                                    &nbsp;
                                    <button
                                      type="button"
                                      class="btn  btn-danger btn-sm"
                                      style={{ width: "70px" }}
                                      onClick={() =>
                                        handleDelete(formData._id, "PAN")
                                      }
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                                <br />
                              </>
                            )}
                            <br />
                            {formData.Passport && (
                              <>
                                <div
                                  className="col-sm-6"
                                  style={{ textAlign: "center" }}
                                >
                                  <p className="text-muted text-sm">
                                    <b>Passport:</b>
                                  </p>
                                  <img
                                    className="img-fluid"
                                    src={formData.Passport}
                                    alt="Passport"
                                    style={{ width: 200, height: 200 }}
                                    onClick={() =>
                                      handleImageClick(formData.Passport)
                                    }
                                  />
                                  <div>
                                    <button
                                      type="button"
                                      class="btn  btn-info btn-sm"
                                      style={{ width: "80px" }}
                                      onClick={() =>
                                        window.open(formData.Passport, "_blank")
                                      }
                                    >
                                      Download
                                    </button>
                                    &nbsp;
                                    <button
                                      type="button"
                                      class="btn  btn-danger btn-sm"
                                      style={{ width: "70px" }}
                                      onClick={() =>
                                        handleDelete(formData._id, "Passport")
                                      }
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                                <br />
                              </>
                            )}

                            {formData.GovtCert && (
                              <>
                                <div
                                  className="col-sm-6"
                                  style={{ textAlign: "center" }}
                                >
                                  <p className="text-muted text-sm">
                                    <b>Govt Cert:</b>
                                  </p>
                                  <img
                                    className="img-fluid"
                                    src={formData.GovtCert}
                                    alt="GovtCert"
                                    style={{ width: 200, height: 200 }}
                                    onClick={() =>
                                      handleImageClick(formData.GovtCert)
                                    }
                                  />
                                  <div>
                                    <button
                                      type="button"
                                      class="btn  btn-info btn-sm"
                                      style={{ width: "80px" }}
                                      onClick={() =>
                                        window.open(formData.GovtCert, "_blank")
                                      }
                                    >
                                      Download
                                    </button>
                                    &nbsp;
                                    <button
                                      type="button"
                                      class="btn  btn-danger btn-sm"
                                      style={{ width: "70px" }}
                                      onClick={() =>
                                        handleDelete(formData._id, "GovtCert")
                                      }
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}

                            {formData.OfferLetter && (
                              <>
                                <div
                                  className="col-sm-6"
                                  style={{ textAlign: "center" }}
                                >
                                  <p className="text-muted text-sm">
                                    <b>Offer Letter:</b>
                                  </p>
                                  <img
                                    className="img-fluid"
                                    src={formData.OfferLetter}
                                    alt="OfferLetter"
                                    style={{ width: 200, height: 200 }}
                                    onClick={() =>
                                      handleImageClick(formData.OfferLetter)
                                    }
                                  />
                                  <div>
                                    <button
                                      type="button"
                                      class="btn  btn-info btn-sm"
                                      style={{ width: "80px" }}
                                      onClick={() =>
                                        window.open(
                                          formData.OfferLetter,
                                          "_blank"
                                        )
                                      }
                                    >
                                      Download
                                    </button>
                                    &nbsp;
                                    <button
                                      type="button"
                                      class="btn btn-danger btn-sm"
                                      style={{ width: "70px" }}
                                      onClick={() =>
                                        handleDelete(
                                          formData._id,
                                          "OfferLetter"
                                        )
                                      }
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />

      <Modal size="lg" show={!!selectedImage} onHide={handleCloseModal}>
        <Modal.Header >
          <Modal.Title>Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="popup-image-container">
            <img
              src={selectedImage}
              alt="Enlarged Blog Image"
              width={760}
              height={500}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
