import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import Header from "../includes/Header";
import Sidebar from "../includes/Sidebar";
import Footer from "../includes/Footer";
import ReactPaginate from "react-paginate";
import Loader from "../Loader";

export default function Coupons() {
  const [coupons, setCoupons] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const couponsPerPage = 10; // Number of coupons to show per page
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axiosInstance.get("/payment/coupons");
        console.log(response.data.result);
        setCoupons(response.data.result.docs); // Ensure this matches your API response structure
        setPageCount(Math.ceil(response.data.result.length / couponsPerPage));
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleEdit = (id) => {
    navigate(`/admin/update_coupon?id=${id}`);
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this coupon?"
    );
    if (confirmDelete) {
      try {
        setIsLoading(true);
        await axiosInstance.delete(`/payment/coupon/${id}`);
        setCoupons((prevCoupons) =>
          prevCoupons.filter((coupon) => coupon._id !== id)
        );
      } catch (error) {
        console.error("Error deleting coupon", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const offset = currentPage * couponsPerPage;
  const currentCoupons = coupons.slice(offset, offset + couponsPerPage);

  if (isLoading) return <Loader />;

  return (
    <div className="maintopmargin" >
      <div className="content-wrapper">
        {/* <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Coupons</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/update_coupon">Add Coupons</Link>
                  </li>
                  <li className="breadcrumb-item active">Coupons</li>
                </ol>
              </div>
            </div>
          </div>
        </section> */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Coupons</h3>
                  </div>
                  <div className="card-body table-responsive p-0">
                    <table className="table table-hover text-nowrap">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Category</th>
                          <th>Code</th>
                          <th>Package</th>
                          <th>Type</th>
                          <th>Status</th>
                          <th>Offer Type</th>
                          <th>Discount Percentage</th>
                          <th>Used Coupon</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentCoupons.map((coupon, index) => (
                          <tr key={coupon._id}>
                            <td>{offset + index + 1}</td>
                            <td>{coupon.Category}</td>
                            <td>{coupon.Code.toUpperCase()}</td>
                            <td>
                              {coupon.PackageName}
                            </td>
                            <td>{coupon.Type}</td>
                            <td>{coupon.Status}</td>
                            <td>{coupon.OfferType}</td>
                            <td>{coupon.DiscountAmount}</td>
                            <td>{coupon.UsedCount}</td>

                            <td>
                              <button
                                type="button"
                                className="btn btn-block btn-primary btn-sm"
                                onClick={() => handleEdit(coupon._id)}
                              >
                                Edit
                              </button>
                              <button
                                type="button"
                                className="btn btn-block btn-danger btn-sm"
                                onClick={() => handleDelete(coupon._id)}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="card-footer clearfix">
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-center"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakLinkClassName={"page-link"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
